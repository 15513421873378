import { createClientSchemaMeta } from '@donkeyjs/client';
import { formatEventStartsEnds } from '@donkeyjs/module-events';
import { PhNewspaper } from '@donkeyjs/phosphor-icons';
import { formatEventName } from './helpers/formatEventName';
import { schemaMeta } from './schemaMeta';

export const clientSchemaMeta = createClientSchemaMeta(schemaMeta, {
  Article: {
    asString: (node) => node.headline,
    icon: () => PhNewspaper,
  },
  Category: {
    asString: (node) => node.name,
  },
  Embed: {
    asString: (node) => node.headline || '',
  },
  Event: {
    asString: (node) =>
      [
        formatEventStartsEnds(node, { timeDelimiter: '|', includeYear: true }),
        formatEventName(node),
      ].join(' '),
  },
  ProgramElement: {
    outline: (node) => ({
      descriptor: null,
      name: node.interval ? (
        <em>~ Interval ~</em>
      ) : (
        node.composition?.toString() || ''
      ),
    }),
  },
  Project: {
    asString: (node) => node.name,
  },
  Review: {
    asString: (node) => node.quote[0]?.text || '',
  },
});
