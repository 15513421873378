import { Blocks, DataFilters, Menu, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { AudioPlayerButton } from '@donkeyjs/module-audio';
import { getSidebar } from '../helpers/sidebarContext';
import styles from './Body.module.css';

export function Body() {
  const sidebar = getSidebar();

  const withSidebar = () => !session.router.route.node?.hideSidebar;

  return () =>
    session.router.route.node?.key === 'home' ? null : (
      <div class={styles.main}>
        <div
          class={bind(() => [
            styles.body,
            { [styles.withoutSidebar]: !withSidebar() },
          ])}
        >
          <div class={styles.main}>
            <Blocks segment="main" />
          </div>
          <Menu class={styles.nav} level={1} expand="active" sublevels={4} />
          <DataFilters />
          {() =>
            withSidebar() && (
              <aside class={styles.aside}>
                {() => sidebar.children}
                <Blocks segment="sidebar" />
              </aside>
            )
          }
          <AudioPlayerButton floating />
        </div>
        <div class={styles.footer}>
          <Blocks on="app" segment="footer" />
        </div>
      </div>
    );
}
