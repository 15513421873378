import { session } from '@donkeyjs/client';
import { getGlobal } from '@donkeyjs/jsx-runtime';
import { store, type DataNode } from '@donkeyjs/proxy';
import type { Howl as HowlType } from 'howler';

export type AudioState = 'idle' | 'loading' | 'playing' | 'paused';

export interface AudioContext {
  state: AudioState;
  playlist: DataNode<DataSchema, 'FileRef'>[];
  currentTrack:
    | DataNode<DataSchema, 'File'>
    | DataNode<DataSchema, 'FileRef'>
    | undefined;
  readonly currentFile: DataNode<DataSchema, 'File'> | undefined;
  readonly nextTrack: DataNode<DataSchema, 'FileRef'> | undefined;
  readonly previousTrack: DataNode<DataSchema, 'FileRef'> | undefined;
  readonly duration: number;
  readonly location: number;

  readonly play: (input?: {
    track?: DataNode<DataSchema, 'File'> | DataNode<DataSchema, 'FileRef'>;
    playlist?: DataNode<DataSchema, 'FileRef'>[];
  }) => void;
  readonly pause: () => void;
  readonly stop: () => void;
  readonly playPrevious: () => void;
  readonly playNext: () => void;
  readonly togglePlay: (input?: {
    track?: DataNode<DataSchema, 'File'> | DataNode<DataSchema, 'FileRef'>;
    playlist?: DataNode<DataSchema, 'FileRef'>[];
  }) => void;

  readonly getTrackState: (
    track: DataNode<DataSchema, 'File'> | DataNode<DataSchema, 'FileRef'>,
  ) => AudioState;
  readonly isInPlaylist: (track: DataNode<DataSchema, 'FileRef'>) => boolean;
}

const key = Symbol();

export const useAudio = () =>
  getGlobal<AudioContext>(key, () => {
    let Howl: typeof HowlType | undefined;
    let howl: HowlType | undefined;

    const onend = () => {
      const next = context.nextTrack;
      if (next) startTrack(next);
      else {
        context.state = 'idle';
        context.currentTrack = undefined;
        howl!.unload();
        howl = undefined;
      }
    };

    const startTrack = async (
      track: DataNode<DataSchema, 'File'> | DataNode<DataSchema, 'FileRef'>,
    ) => {
      context.currentTrack = track;
      context.state = 'loading';
      const { data: url } = await session.data.query.signedFileUrl({
        fileId: track.__typename === 'File' ? track.id : track.file.id,
      });
      if (!url) return;

      if (!Howl) Howl = (await import('howler')).Howl;
      howl?.unload();

      howl = new Howl({
        autoplay: true,
        format: [
          track.__typename === 'File'
            ? track.fileExtension
            : track.file.fileExtension,
        ],
        onend,

        onloaderror: (_, err) => {
          console.log(err);
        },
        onpause: () => {
          context.state = 'paused';
        },
        onplay: () => {
          context.state = 'playing';
        },
        onplayerror: (_, err) => {
          console.log(err);
        },
        src: [url as string],
      });
    };

    const context: AudioContext = store<AudioContext>({
      currentTrack: undefined,
      get currentFile() {
        return !this.currentTrack || this.currentTrack.__typename === 'File'
          ? this.currentTrack
          : this.currentTrack.file;
      },
      get previousTrack() {
        return !this.currentTrack || this.currentTrack.__typename !== 'FileRef'
          ? undefined
          : this.playlist[this.playlist.indexOf(this.currentTrack) - 1];
      },
      get nextTrack() {
        return !this.currentTrack || this.currentTrack.__typename !== 'FileRef'
          ? undefined
          : this.playlist[this.playlist.indexOf(this.currentTrack) + 1];
      },
      get duration() {
        return howl?.duration() || 0;
      },
      get location() {
        return (howl?.seek() as number) || 0;
      },
      playlist: [],
      state: 'idle',

      play: (input = {}) => {
        if (input.track && input.track !== context.currentTrack) {
          startTrack(input.track);
          context.playlist = input.playlist || [];
        } else if (!input.track && input.playlist) {
          context.playlist = input.playlist;
          if (input.playlist.length) startTrack(input.playlist[0]);
        } else if (howl) howl.play();
        else if (context.playlist.length) startTrack(context.playlist[0]);
      },
      pause: () => {
        howl?.pause();
      },
      stop: () => {
        howl?.stop();
        context.state = 'idle';
        context.currentTrack = undefined;
        howl!.unload();
        howl = undefined;
      },
      playPrevious: () => {
        if (context.previousTrack) startTrack(context.previousTrack);
      },
      playNext: () => {
        if (context.nextTrack) startTrack(context.nextTrack);
      },
      togglePlay: (input = {}) => {
        const track = input.track || input.playlist?.[0];
        if (
          context.state === 'playing' &&
          (!track || context.currentTrack === track)
        )
          howl?.pause();
        else context.play(input);
      },

      getTrackState: (track) =>
        context.currentTrack === track ? context.state : 'idle',
      isInPlaylist: (track) => context.playlist.includes(track),
    });

    return context;
  });
