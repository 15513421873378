import { Loader } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { map, meta, type DataNode } from '@donkeyjs/proxy';

interface RelatedReviewsProps {
  reviews: { review: DataNode<DataSchema, 'Review'>; $: any }[];
}

export function RelatedReviews(props: RelatedReviewsProps) {
  return (
    <Loader
      loading={bind(() => meta(props.reviews as any)?.isLoading)}
      type="none"
    >
      {map(
        () => props.reviews,
        (rel) => (
          <rel.$.review />
        ),
      )}
    </Loader>
  );
}
