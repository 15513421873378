import {
  batch,
  store,
  type Culture,
  type DataNode,
  type Store,
} from '@donkeyjs/proxy';
import { traceRouting } from '../trace';
import { createRoutingMap, type RoutingMap } from './RoutingMap';
import { encodeQuery } from './helpers/encodeAndDecodeQuery';
import {
  getRouterStateFromPath,
  route404,
} from './helpers/getRouterStateFromPath';
import type { RouterPlugin, UrlQuery } from './types';

export interface Router {
  routes: { [culture in Culture]?: RouteDefinition[] };
  map: RoutingMap;
  readonly cultures: Culture[];
  readonly defaultCulture: Culture;
  readonly plugins: RouterPlugin[];

  readonly hostname: string;
  path: string;
  pathname: string;
  queryString: string;

  readonly history: RouterHistoryRecord[];
  lastMutation: {
    replace: boolean;
    changedRoute: boolean;
    anchor?: any;
    loginRequested?: boolean;
  };
  findInHistory(
    predicate: (record: RouterHistoryRecord) => unknown,
  ): RouterHistoryRecord | undefined;

  culture: Culture;
  route: RouteDefinition;
  query: Store<UrlQuery>;

  getRoutesForParent(parent: RouteDefinition | undefined): RouteDefinition[];
  getBranch(route?: RouteDefinition): RouteDefinition[];
  getRouteByKey(key: string): RouteDefinition | undefined;
  getRouteById(key: string): RouteDefinition | undefined;
  prependPathWithHostname(path: string): string;

  navigate(
    pathname: string,
    options?: {
      queryString?: string;
      replace?: boolean;
      anchor?: any;
      no404?: boolean;
      forceRefresh?: boolean;
    },
  ): void;
  updateQuery(updates: UrlQuery): void;
}

export type RouterOptions = Pick<
  Router,
  'routes' | 'cultures' | 'defaultCulture' | 'hostname' | 'plugins'
>;

export type RouterInput<Plugin extends object = {}> = RouterOptions &
  Pick<Router, 'pathname' | 'queryString'> & {
    plugin?: Plugin;
  };

export interface RouteDefinition {
  id: string;
  name: string;
  pathname: string;
  fullPathname?: string;
  redirect?: string;
  parent?: RouteDefinition;
  children?: RouteDefinition[];
  isSystemRoute?: boolean;
  node?: DataNode<DataSchema, 'Route'>;
  render?: (renderChildren: any) => any;
}

export interface RouterHistoryRecord {
  pathname: string;
  queryString: string;
  route: RouteDefinition;
  replace?: boolean;
  anchor?: unknown;
  loginRequested?: boolean;
}

export const createRouter = <Plugin extends object = {}>(
  input: RouterInput<Plugin>,
): Router & Plugin => {
  const options = store(input);

  const router: Router = store(
    Object.defineProperties<Router>(
      {
        get routes() {
          return options.routes;
        },

        set routes(value) {
          options.routes = value;
          router.map.update(value);
          update(
            router.history[router.history.length - 1]?.pathname ||
              router.pathname,
            {
              queryString: router.queryString,
              forceRefresh: true,
              replace: true,
              no404: true,
            },
          );
        },

        get path(): string {
          return [
            typeof router.route === 'object'
              ? options.cultures.length > 1 &&
                router.culture !== options.defaultCulture
                ? [
                    `/${(router.culture as Culture).toLowerCase()}`,
                    router.route.pathname,
                  ].join('')
                : router.route.pathname
              : `/${router.route}`,
            encodeQuery(router.query),
          ]
            .filter(Boolean)
            .join('?');
        },

        get pathname(): string {
          return this.path.split('?')[0];
        },

        get queryString(): string {
          return this.path.split('?')[1] || '';
        },

        get plugins() {
          return options.plugins;
        },

        history: [],
        findInHistory(predicate) {
          return router.history.slice().reverse().find(predicate);
        },

        map: createRoutingMap(options),
        get hostname() {
          return input.hostname;
        },
        cultures: options.cultures,
        defaultCulture: options.defaultCulture,

        culture: input.defaultCulture,
        route: route404,
        query: store<UrlQuery>({}),

        lastMutation: {
          changedRoute: false,
          replace: false,
        },

        getRoutesForParent(parent) {
          return parent
            ? parent.children || []
            : router.map.rootLevel(router.culture);
        },

        getBranch(route = router.route) {
          const result: RouteDefinition[] = [route];
          let current = route;
          while (current.parent) {
            current = current.parent;
            result.push(current);
          }
          return result.reverse();
        },

        getRouteByKey(key) {
          return router.map.routeByKey(router.culture, key);
        },

        getRouteById(id) {
          return router.map.routeById(router.culture, id);
        },

        navigate(path, options) {
          let [pathname, queryString] = path.split('?');
          if (options?.queryString) queryString = options.queryString;
          const currentPathname = router.pathname;
          const currentQueryString = router.queryString;
          if (
            options?.forceRefresh ||
            pathname !== currentPathname ||
            queryString !== currentQueryString
          ) {
            update(pathname, { ...options, queryString });
          }
        },

        updateQuery(updates) {
          router.navigate(router.pathname, {
            queryString: encodeQuery({ ...router.query, ...updates }),
          });
        },

        prependPathWithHostname(path) {
          return `${this.hostname}${path}`;
        },
      },
      Object.getOwnPropertyDescriptors(input.plugin || {}),
    ),
    { name: 'router' },
  );

  const update: Router['navigate'] = (pathname, navOptions) => {
    const { culture, route, query, anchor, loginRequested } =
      getRouterStateFromPath(
        router,
        pathname,
        navOptions?.queryString,
        navOptions?.anchor,
        input.plugins,
      );

    if (navOptions?.no404 && route === route404) return;

    const queryString = encodeQuery(query);

    if (
      !navOptions?.forceRefresh &&
      culture === router.culture &&
      route === router.route &&
      queryString === encodeQuery(router.query)
    )
      return;

    batch(() => {
      router.history.push({
        pathname,
        queryString,
        anchor,
        route,
        replace: navOptions?.replace,
        loginRequested,
      });
      router.lastMutation = {
        changedRoute: route !== router.route,
        replace: navOptions?.replace || false,
        anchor,
        loginRequested,
      };

      router.culture = culture;
      router.route = route;
      store.replace(router.query, query);

      if (route.node) traceRouting(pathname, route.node.toString());
    });
  };

  if (!options.plugin) {
    update(options.pathname, {
      queryString: options.queryString,
      replace: true,
    });
  }

  return router as Router & Plugin;
};

export function getMailContextFromUrl(router: Router) {
  const [context] = router.query['--mail-context'] || [];
  return isValidMailContext(context) ? context : undefined;
}

const isValidMailContext = (
  context: string | undefined,
): context is 'browser' | 'mail' =>
  !!context && ['browser', 'mail'].includes(context);
