import { createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta } from '@donkeyjs/proxy';
import styles from './ViewReviewSlideShow.module.css';

export const ViewReviewSlideShow = createView<DataSchema, 'Review'>(
  {
    name: () => 'Slide Show',
    enableAsBlock: true,
  },

  (props) => {
    meta(props.node).request({ publishedAt: true });

    return (
      <div class={bind(() => [props.class])} onmount={props.onmount}>
        <div class={styles.quote}>
          <props.node.$.quote />
        </div>
        <div class={styles.publisher}>
          <div class={styles.info}>
            <props.node.$.publisher />
            {() => {
              !props.node.hideDate && (
                <span class={styles.date}>
                  <props.node.$.publishedAt formatter=" MMM yyy" />
                </span>
              );
            }}
          </div>
        </div>
      </div>
    );
  },
);
