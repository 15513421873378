import type { BaseSchemaInput } from '@donkeyjs/core';
import {
  createSchemaExtension,
  validateEmailAddress,
  type DataList,
  type SchemaFromInput,
  type SchemaInputWithExtensions,
} from '@donkeyjs/proxy';
import { COUNTRY_CODES } from './COUNTRY_CODES';
import { CURRENCIES } from './CURRENCIES';

export const shoppingSchemaOrderable = {
  price: 'float',
  currency: ['string', { size: 10 }],
  weight: 'float?',
} as const;

export interface Orderable {
  id: string;
  price: number;
  currency: string;
  weight?: number | null;
  orderItems: DataList<DataSchema, 'OrderItem'>;
}

export const shoppingSchemaExtension = createSchemaExtension<BaseSchemaInput>()(
  {
    enums: {
      CountryCode: COUNTRY_CODES,
      Currency: CURRENCIES,
      OrderStage: [
        'SHOPPING',
        'SHIPPING',
        'DELIVERING',
        'COMPLETED',
        'CANCELED',
      ],
      TransactionType: ['REQUEST', 'PAYMENT', 'COUPON'],
      TransactionStatus: ['SCHEDULED', 'STARTED', 'FAILED', 'SUCCEEDED'],
    },
    nodes: {
      Address: {
        companyName: ['string?', { size: 80 }],
        title: ['string?', { size: 80 }],
        firstName: ['string?', { size: 80 }],
        lastName: ['string?', { size: 80 }],
        middleName: ['string?', { size: 80 }],
        city: ['string', { size: 80 }],
        countryCode: 'CountryCode',
        addressLine1: ['string', { size: 80 }],
        addressLine2: ['string?', { size: 80 }],
        postalCode: ['string', { size: 80 }],
        user: ['User?', { reverse: 'addresses', cascadeDelete: true }],
      },
      Coupon: {
        code: ['string', { size: 80 }],
        isPercentage: 'boolean?',
        amount: 'float',
        name: ['string', { i18n: true }],
        minimumOrderSum: 'float?',
        validFrom: 'date?',
        validUntil: 'date?',
      },
      Order: {
        email: ['string', { validate: validateEmailAddress }],
        secret: ['string', { size: 10 }],
        orderNumber: 'int?',
        orderedAt: 'date',
        stage: 'OrderStage',
        orderCulture: ['string', { size: 10 }],
        exception: 'string?',

        currency: 'Currency',

        shippingCompanyName: ['string?', { size: 80 }],
        shippingTitle: ['string?', { size: 80 }],
        shippingFirstName: ['string', { size: 80 }],
        shippingLastName: ['string', { size: 80 }],
        shippingMiddleName: ['string?', { size: 80 }],
        shippingCountryCode: 'CountryCode',
        shippingCity: ['string?', { size: 80 }],
        shippingAddressLine1: ['string?', { size: 80 }],
        shippingAddressLine2: ['string?', { size: 80 }],
        shippingPostalCode: ['string?', { size: 80 }],

        billingCompanyName: ['string?', { size: 80 }],
        billingTitle: ['string?', { size: 80 }],
        billingFirstName: ['string', { size: 80 }],
        billingLastName: ['string', { size: 80 }],
        billingMiddleName: ['string?', { size: 80 }],
        billingCountryCode: 'CountryCode',
        billingCity: ['string?', { size: 80 }],
        billingAddressLine1: ['string?', { size: 80 }],
        billingAddressLine2: ['string?', { size: 80 }],
        billingPostalCode: ['string?', { size: 80 }],

        shipToBillingAddress: 'boolean?',

        user: ['User?', { reverse: 'orders' }],
      },
      OrderItem: {
        order: [
          'Order',
          {
            reverse: 'items',
            sort: [{ name: 'Default', order: ['sortIndex:auto'] }],
            embeddedList: true,
          },
        ],
        quantity: 'int',
        price: 'float',
        weight: 'float?',
        sortIndex: 'float',
      },
      Transaction: {
        type: 'TransactionType',
        status: 'TransactionStatus',
        isPercentage: 'boolean?',
        amount: 'float',
        at: 'date',
        providerData: 'json?',
        message: 'string?',
        diagnostics: 'json?',
        order: [
          'Order',
          {
            reverse: 'transactions',
            sort: [{ name: 'CREATED', order: ['createdAt:desc'] }],
          },
        ],
      },
      ShippingThreshold: {
        app: ['App?', { reverse: 'shippingThresholds', cascadeDelete: true }],
        region: ['string', { size: 3 }],
        fromWeight: 'float?',
        fromQuantity: 'int?',
        price: 'float',
      },
      TransactionCoupon: {
        coupon: ['Coupon', { reverse: 'coupons' }],
        transaction: ['Transaction', { reverse: 'transactions' }],
      },
    },

    extensions: {
      App: {
        add: {
          defaultCurrency: 'Currency',
          shippingFromCountry: 'CountryCode?',
          shippingBasePrice: 'float?',
          shippingBaseWeight: 'float?',
        },
      },
    },

    resolvers: {
      Order: {
        order: { return: 'one' },

        orders: {
          return: 'many',
          where: {
            stage: ['stage', ['eq']],
          },
          sort: [{ name: 'Default', order: ['orderedAt:desc'] }],
        },
      },
    },

    customResolvers: {
      mutations: {
        getPaymentIntent: {
          args: { target: 'string' },
          returns: 'string',
        },

        resendOrderConfirmation: {
          args: { paymentTarget: 'string', orderId: 'string' },
          returns: 'boolean',
        },
      },
    },

    i18n: {
      'en-GB': () => import('./i18n/enGB').then((m) => m.SchemaEnGB),
      nl: () => import('./i18n/nlNL').then((m) => m.SchemaNl),
    },
  } as const,
);

export type ShoppingSchemaInput = SchemaInputWithExtensions<
  BaseSchemaInput,
  typeof shoppingSchemaExtension
>;

export type ShoppingSchema = SchemaFromInput<ShoppingSchemaInput>;

export const productsSchemaExtension =
  createSchemaExtension<ShoppingSchemaInput>()({
    nodes: {
      Product: {
        name: ['string', { i18n: true }],
        sortIndex: 'float?',
      },
      ProductOption: {
        name: ['string', { i18n: true }],
        product: [
          'Product',
          { reverse: 'options', embeddedList: true, cascadeDelete: true },
        ],
        sortIndex: 'float?',
        ...shoppingSchemaOrderable,
      },
    },

    extensions: {
      OrderItem: {
        add: {
          productOption: [
            'ProductOption?',
            { reverse: 'orderItems', embeddedList: true },
          ],
        },
      },

      FileRef: {
        add: {
          product: [
            'Product?',
            { reverse: 'images', embeddedList: true, cascadeDelete: true },
          ],
        },
      },
    },

    resolvers: {
      Product: {
        products: {
          return: 'many',
          sort: [
            { name: 'Name', order: ['name:asc'] },
            { name: 'Sorted', order: ['sortIndex:auto'] },
          ],
        },
      },
    },
  } as const);

export type ProductsSchemaInput = SchemaInputWithExtensions<
  ShoppingSchemaInput,
  typeof productsSchemaExtension
>;

export type ProductsSchema = SchemaFromInput<ProductsSchemaInput>;
