import type { FieldRenderProps } from '../..';
import type { I18nContext } from '../../../i18n/getI18n';

export function getNumberValue(
  value: number | null | undefined,
  formatter: FieldRenderProps<'float'>['formatter'],
  i18n: I18nContext,
  readonly?: boolean,
) {
  return (
    (readonly && formatter
      ? value == null
        ? null
        : typeof formatter === 'function'
          ? formatter(value, i18n)
          : i18n.formatNumber(value, formatter)
      : value?.toString()) ?? ''
  );
}
