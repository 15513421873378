import type { CreateAppInput } from '@donkeyjs/core';
import { enGB } from '@donkeyjs/i18n-en-gb';
import { permissions } from './permissions';
import { schema } from './schema';
import { schemaMeta } from './schemaMeta';

export const settings: CreateAppInput = {
  hostname: 'www.simoncallaghan.com',
  key: 'simon',
  permissions,
  schema,
  schemaMeta,
  locales: { 'en-GB': enGB },
};
