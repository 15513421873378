import type { DataNode } from '@donkeyjs/proxy';
import { getShoppingSettings } from '../client';

export function parseOrderItem(item: DataNode<DataSchema, 'OrderItem'>) {
  const settings = getShoppingSettings();

  const parseOrderItem = settings.parseOrderItem;
  if (!parseOrderItem)
    throw new Error('Missing parseOrderItem in shoppingClient options');

  return parseOrderItem(item);
}
