import type { SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  Composer: {
    searchVectors: {
      lastName: 'A',
      firstName: 'B',
      middleName: 'C',
      catalogueSystem: 'D',
    },
  },
  Composition: {
    searchVectors: {
      name: 'A',
      number: 'A',
      opusNumber: 'A',
      opusNumberCardinal: 'A',
      nickname: 'A',
      yearAsText: 'A',
      'composer.lastName': 'B',
      'composer.firstName': 'B',
      'composer.middleName': 'B',
      'arranger.lastName': 'C',
      'arranger.firstName': 'C',
      'arranger.middleName': 'C',
      dedicatee: 'D',
      songText: 'D',
      premiere: 'D',
      yearNotes: 'D',
    },
  },
};
