import { blocksSort, type BaseSchemaInput } from '@donkeyjs/core';
import {
  createSchemaExtension,
  type SchemaFromInput,
  type SchemaInputWithExtensions,
} from '@donkeyjs/proxy';

export const compositionsSort = [
  {
    name: 'DEFAULT',
    order: [
      'composer.lastName:asc',
      'name:asc',
      'number:asc',
      'catalogueNumber:asc',
      'opusNumber:asc',
      'opusNumberCardinal:asc',
    ],
  },
  { name: 'YEAR', order: ['yearAsNumber:asc'] },
  { name: 'YEAR_DESC', order: ['yearAsNumber:desc'] },
] as const;

export const musicSchemaExtension = createSchemaExtension<BaseSchemaInput>()({
  enums: {
    Tonality: [
      'A_MAJOR',
      'A_MINOR',
      'A_FLAT_MAJOR',
      'A_FLAT_MINOR',
      'A_SHARP_MAJOR',
      'A_SHARP_MINOR',
      'B_MAJOR',
      'B_MINOR',
      'B_FLAT_MAJOR',
      'B_FLAT_MINOR',
      'B_SHARP_MAJOR',
      'B_SHARP_MINOR',
      'C_MAJOR',
      'C_MINOR',
      'C_FLAT_MAJOR',
      'C_FLAT_MINOR',
      'C_SHARP_MAJOR',
      'C_SHARP_MINOR',
      'D_MAJOR',
      'D_MINOR',
      'D_FLAT_MAJOR',
      'D_FLAT_MINOR',
      'D_SHARP_MAJOR',
      'D_SHARP_MINOR',
      'E_MAJOR',
      'E_MINOR',
      'E_FLAT_MAJOR',
      'E_FLAT_MINOR',
      'E_SHARP_MAJOR',
      'E_SHARP_MINOR',
      'F_MAJOR',
      'F_MINOR',
      'F_FLAT_MAJOR',
      'F_FLAT_MINOR',
      'F_SHARP_MAJOR',
      'F_SHARP_MINOR',
      'G_MAJOR',
      'G_MINOR',
      'G_FLAT_MAJOR',
      'G_FLAT_MINOR',
      'G_SHARP_MAJOR',
      'G_SHARP_MINOR',
    ],
  },

  nodes: {
    Composer: {
      firstName: 'string?',
      middleName: 'string?',
      lastName: 'string',
      yearOfBirth: 'int?',
      yearOfDeath: 'int?',
      catalogueSystem: 'string?',
    },

    Composition: {
      composer: [
        'Composer?',
        { reverse: 'compositions', sort: compositionsSort },
      ],
      arranger: [
        'Composer?',
        { reverse: 'arrangements', sort: compositionsSort },
      ],
      name: ['string', { i18n: true }],
      number: 'string?',
      opusNumber: 'string?',
      opusNumberCardinal: 'string?',
      catalogueNumber: 'string?',
      tonality: 'Tonality?',
      yearAsNumber: 'int?',
      yearAsText: 'string?',
      duration: 'int?',
      dedicatee: 'string?',
      orchestration: 'string?',
      nickname: ['string?', { i18n: true }],
      songText: ['string?', { i18n: true }],
      premiere: ['string?', { i18n: true }],
      yearNotes: ['string?', { i18n: true, markup: true }],
    },

    CompositionTag: {
      tag: ['Tag', { reverse: 'compositions', cascadeDelete: true }],
      composition: ['Composition', { reverse: 'tags', cascadeDelete: true }],
    },

    ProgramElement: {
      composition: [
        'Composition?',
        { reverse: 'programmes', cascadeDelete: true },
      ],
      note: ['string?', { i18n: true, markup: true }],
      interval: 'boolean?',
      sortIndex: 'float',
    },
  },

  extensions: {
    Block: {
      add: {
        onCompositionNotes: [
          'Composition?',
          { reverse: 'notes', sort: blocksSort, cascadeDelete: true },
        ],
        onCompositionInternalNotes: [
          'Composition?',
          { reverse: 'internalNotes', sort: blocksSort, cascadeDelete: true },
        ],
      },
    },
  },

  resolvers: {
    Composer: {
      composers: {
        return: 'many',
        sort: [
          {
            name: 'LAST_NAME_ASC',
            order: ['lastName:asc', 'firstName:asc'],
          },
        ],
      },
    },

    Composition: {
      compositions: {
        return: 'many',
        sort: compositionsSort,
        where: {
          tonality: ['tonality', ['eq', 'in']],
          composer: ['composer', ['eq', 'in']],
          arranger: ['arranger', ['eq', 'in']],
          tags: ['tags.tag', ['eq', 'in']],
        },
      },
    },
  },

  i18n: {
    'en-GB': () => import('./i18n/enGB').then((m) => m.SchemaEnGB),
    nl: () => import('./i18n/nlNL').then((m) => m.SchemaNl),
  },
} as const);

export type MusicSchema = SchemaFromInput<
  SchemaInputWithExtensions<BaseSchemaInput, typeof musicSchemaExtension>
>;
