import { createLayout } from './createLayout';
import { InlineBlock, InlineBlocks } from './fields/blocks/InlineBlock';
import { InlineDate } from './fields/date/InlineDate';
import { InlineFileRef, InlineFileRefs } from './fields/fileRefs/InlineFileRef';
import { InlineFile } from './fields/files/InlineFile';
import { InlineFloat } from './fields/float/InlineFloat';
import { InlineInt } from './fields/int/InlineInt';
import { LayoutJson } from './fields/json/LayoutJson';
import { InlineNodeField } from './fields/node/InlineNode';
import { InlineNodeList } from './fields/nodeList/InlineNodeList';
import { InlinePlace } from './fields/places/InlinePlace';
import { InlineString } from './fields/string/InlineString';

export const inlineLayout = createLayout({
  fields: {
    float: InlineFloat,
    int: InlineInt,
    date: InlineDate,
    json: LayoutJson((() => inlineLayout) as any),
    node: InlineNodeField,
    nodeList: InlineNodeList,
    string: InlineString,
    Block: InlineBlock,
    BlockList: InlineBlocks,
    File: InlineFile,
    FileRef: InlineFileRef,
    FileRefList: InlineFileRefs,
    Place: InlinePlace,
  },
});
