import { getContext, getGlobal, setContext } from '@donkeyjs/jsx-runtime';
import type {
  DataNode,
  FieldSchema,
  NodeSchema,
  Schema,
} from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';

const key = Symbol('node-context');
const providersKey = Symbol('node-context-providers');

export interface NodeContext {
  readonly typename?: string;
  readonly schema?: NodeSchema;
  readonly fields: string[];
  readonly node?: DataNode<Schema> | null;
  readonly readonly: boolean;
  fieldSchema(name: string): FieldSchema | undefined;

  readonly steps?: DataNode<DataSchema, 'Block'>[] | undefined;
  step?: DataNode<DataSchema, 'Block'> | undefined;
  goNextStep(): void;
  goPreviousStep(): void;
}

interface NodeContextProvider {
  name: () => string;
  get: () => DataNode<Schema> | null | undefined;
}

export interface NodeContextProviders {
  providers: Record<string, NodeContextProvider>;
  register: (key: string, provider: NodeContextProvider) => void;
}

export function getNodeContextProviders() {
  return getGlobal<NodeContextProviders>(providersKey, () => ({
    providers: {},
    register(name, provider) {
      this.providers[name] = provider;
    },
  }));
}

export function setNodeContext(
  context: Pick<NodeContext, 'typename' | 'schema' | 'node' | 'steps' | 'step'>,
) {
  const user = getUserContext();

  setContext<NodeContext>(key, {
    get typename() {
      return context.typename;
    },
    get schema() {
      return context.schema;
    },
    get node() {
      return context.node;
    },
    get steps() {
      return context.steps;
    },
    get step() {
      return context.step;
    },
    set step(value) {
      context.step = value;
    },
    get fields() {
      return [
        ...Object.keys(context.schema?.fields || {}),
        ...Object.keys(context.schema?.reverseFields || {}),
      ];
    },
    get readonly() {
      return !context.typename || !user.can('update', context.typename);
    },
    goNextStep() {
      const steps = context.steps;
      if (steps && context.step) {
        const index = steps.indexOf(context.step);
        if (index < steps.length - 1) {
          context.step = steps[index + 1];
        }
      }
    },
    goPreviousStep() {
      const steps = context.steps;
      if (steps && context.step) {
        const index = steps.indexOf(context.step);
        if (index > 0) {
          context.step = steps[index - 1];
        }
      }
    },
    fieldSchema(name) {
      return (
        context.schema?.fields[name] || context.schema?.reverseFields[name]
      );
    },
  });
}

export function getNodeContext() {
  return getContext<NodeContext | undefined>(key);
}
