import {
  map,
  type DataList,
  type DataNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';

export function Each<S extends Schema, T extends NodeTypename<S>>(props: {
  of: DataList<S, T> | null | undefined;
  render: (
    value: DataNode<S, T>,
    index: () => number,
    array: DataNode<S, T>[],
  ) => JSX.Children;
}): JSX.Children;
export function Each<T>(props: {
  of: T[] | null | undefined;
  render: (value: T, index: () => number, array: T[]) => JSX.Children;
}): JSX.Children;
export function Each<T>(props: {
  of: T[] | null | undefined;
  render: (value: T, index: () => number, array: T[]) => JSX.Children;
}): JSX.Children {
  return <>{map(() => props.of, props.render)}</>;
}
