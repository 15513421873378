import { Loader } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { map, meta, type DataNode } from '@donkeyjs/proxy';

interface RelatedProductsProps {
  products: { product: DataNode<DataSchema, 'Product'>; $: any }[];
}

export function RelatedProducts(props: RelatedProductsProps) {
  return (
    <Loader
      loading={bind(() => meta(props.products as any)?.isLoading)}
      type="none"
    >
      {map(
        () => props.products,
        (rel) => (
          <rel.$.product />
        ),
      )}
    </Loader>
  );
}
