import { map } from '@donkeyjs/proxy';
import styles from './AudioPlaylist.module.css';
import { useAudio } from './useAudio';

interface AudioPlaylistProps {
  class?: string;
}

export function AudioPlaylist(props: AudioPlaylistProps) {
  const audio = useAudio();

  return () =>
    !audio.playlist.length ? null : (
      <ul class={[styles.playlist, props.class]}>
        {map(
          () => audio.playlist,
          (track) => (
            <li
              class={() => ({
                [styles.active]: track === audio.currentTrack,
              })}
            >
              <button
                type="button"
                onclick={() => {
                  audio.play({ track });
                }}
              >
                {track.name}
              </button>
            </li>
          ),
        )}
      </ul>
    );
}
