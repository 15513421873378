import { getGlobal, onMount } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';

const contextKey = Symbol('taskbar');

export const getToolbars = () =>
  getGlobal<{ toolbars: JSX.Children[] }>(contextKey, () =>
    store({ toolbars: [] }),
  );

export const useToolbar = (children: JSX.Children) => {
  const context = getToolbars();

  onMount(() => {
    context.toolbars = [...context.toolbars, children];

    return () => {
      context.toolbars = context.toolbars.filter((t) => t !== children);
    };
  });
};
