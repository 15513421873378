import { WithHeading } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Tile.module.css';

export interface TileProps {
  image?: (ratio: number) => JSX.Children;
  readonly?: boolean;
  class?: JSX.ClassNames;
  kicker?: JSX.Children;
  date?: JSX.Children;
  title: JSX.Children;
  subtitle?: JSX.Children;
  children?: JSX.Children;
  append?: JSX.Children;
  button?: JSX.Children;
  overflow?: boolean;
  small?: boolean;
  square?: boolean;
  media?: boolean;
  card?: boolean;
  expanded?: boolean;
  linkTo?: string;
  onmousedown?: (e: MouseEvent) => void;
}

export function Tile(props: TileProps) {
  return (
    <article
      class={bind(() => [
        props.class,
        styles.box,
        'box',
        'fixed',
        {
          small: props.small,
          [styles.square]: props.square,
          [styles.media]: props.media,
          [styles.card]: props.card,
        },
      ])}
      onmousedown={props.onmousedown}
    >
      {() =>
        props.image?.(
          props.square ? 1 : props.small ? 2 : props.media ? 16 / 9 : 4 / 3,
        )
      }
      <div class={styles.info}>
        {() =>
          (props.kicker || props.date) && (
            <div class={styles.details}>
              {props.kicker && (
                <span class={styles.kicker}>{props.kicker}</span>
              )}
              {props.date && <span class={styles.date}>{props.date}</span>}
            </div>
          )
        }
        <WithHeading heading={bind(() => props.title)}>
          {() => props.subtitle}
          {() =>
            props.children && <div class={styles.text}>{props.children}</div>
          }
        </WithHeading>
      </div>
      {() => props.button && <div class={styles.button}>{props.button}</div>}
    </article>
  );
}
