import type { DataNode } from '@donkeyjs/proxy';

export const formatEventName = (
  event: DataNode<DataSchema, 'Event'>,
  { ignorePromotor }: { ignorePromotor?: boolean } = {},
): string => {
  const promotor = ignorePromotor ? undefined : event.promotor;
  const venueName = event.venueName;
  const cityAndCountry = !event.venue
    ? ''
    : [event.venue.city, event.venue.country?.replace('United Kingdom', 'UK')]
        .filter((s) => !!s)
        .join(', ');

  return promotor
    ? [promotor, cityAndCountry].filter((s) => !!s).join(', ')
    : venueName ||
        (!event.venue
          ? ''
          : [event.venue.name, cityAndCountry].filter((s) => !!s).join(', '));
};
