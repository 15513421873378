import {
  Errors,
  getTheme,
  session,
  setLayout,
  text,
  useForm,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store } from '@donkeyjs/proxy';
import { I18nMailings } from '../../i18n';
import styles from './ManageSubscription.module.css';

const key = Symbol();

interface ManageSubscriptionProps {
  manageId: string;
}

export function ManageSubscription(props: ManageSubscriptionProps) {
  const theme = getTheme();
  setLayout('html');

  const state = store({
    subscriberEmail: '' as string | null,
    get isChangingEmail() {
      return session.router.query.action?.[0] === 'change';
    },
    set isChangingEmail(value) {
      if (value) {
        session.router.query.action = ['change'];
      } else {
        session.router.query.action = [];
      }
    },
    status: 'idle' as 'idle' | 'loading' | 'ready' | 'error',
  });

  const changeForm = useForm(key, { email: 'string' });

  const handleSubmitEmail = async () => {
    state.status = 'loading';
    const email = changeForm.values.email;
    try {
      const { errors } = await session.data.mutation.updateSubscription({
        email,
        manageId: props.manageId,
      });
      if (errors) {
        meta(changeForm.values).errors = errors;
        state.status = 'idle';
      } else {
        state.status = 'ready';
      }
    } catch (error) {
      console.error(error);
      state.status = 'error';
    }
  };

  const handleUnsubscribe = async () => {
    state.status = 'loading';
    try {
      const { errors } = await session.data.mutation.unsubscribe({
        manageId: props.manageId,
      });
      if (errors) {
        meta(changeForm.values).errors = errors;
        state.status = 'idle';
      } else {
        state.status = 'ready';
      }
    } catch (error) {
      console.error(error);
      state.status = 'error';
    }
  };

  return (
    <div
      class={bind(() => [
        styles.manage,
        { loading: state.status === 'loading' },
      ])}
    >
      {() => {
        if (props.manageId === 'preview') {
          return text(
            I18nMailings,
            'Subscription.Modify.UnsubscribeFromPreview',
          );
        }

        if (state.subscriberEmail === null) {
          return text(I18nMailings, 'Subscription.Modify.InvalidInput');
        }

        if (state.status === 'error') {
          return text(I18nMailings, 'Subscription.Modify.Error');
        }

        if (state.status === 'ready') {
          return state.isChangingEmail
            ? text(I18nMailings, 'Subscription.Modify.EmailChanged')
            : text(I18nMailings, 'Subscription.Modify.Unsubscribed');
        }

        if (state.isChangingEmail) {
          return (
            <>
              <Errors node={changeForm.values} />
              <p class={theme.class.paragraph}>
                {text(I18nMailings, 'Subscription.Modify.NewEmailAddress')}
              </p>
              <changeForm.values.$.email label={null} autofocus />
              <div class={['buttons', theme.class.buttons]}>
                <button
                  type="submit"
                  class={['button', theme.class.button, 'default']}
                  disabled={state.status === 'loading'}
                  onclick={() => {
                    handleSubmitEmail();
                  }}
                >
                  {text(I18nMailings, 'Subscription.Modify.UnsubscribeChange')}
                </button>
              </div>
            </>
          );
        }

        return (
          <>
            <Errors node={changeForm.values} />
            {text(I18nMailings, 'Subscription.Modify.UnsubscribeOrChange')}
            <div class={['buttons', theme.class.buttons]}>
              <button
                type="submit"
                class={['button', theme.class.button, 'default']}
                disabled={state.status === 'loading'}
                onclick={() => {
                  state.isChangingEmail = true;
                }}
              >
                ( text(I18nMailings, 'Subscription.Modify.UnsubscribeChange')
                bind)
              </button>
              <button
                type="submit"
                class={['button', theme.class.button]}
                disabled={state.status === 'loading'}
                onclick={() => {
                  handleUnsubscribe();
                }}
              >
                {text(I18nMailings, 'Subscription.Modify.Unsubscribe')}
              </button>
            </div>
          </>
        );
      }}
    </div>
  );
}

//   return isChangingEmail ? (
//     <>
//       <ChangeEmailForm.Errors set={i18n.Errors} />
//       {wrapBox(
//         <Form layout="htmlForm" flow="fluid">
//           <ChangeEmailForm.email />
//           <FormButtons>
//             <ChangeEmailForm.Button action="submit">
//               {i18n.SubmitNewEmail}
//             </ChangeEmailForm.Button>
//           </FormButtons>
//         </Form>,
//       )}
//     </>
//   ) : (
//     <>
//       <UnsubscribeForm.Errors set={i18n.Errors} />
//       {wrapBox(
//         <>
//           <P>{i18n.UnsubscribeOrChange}</P>
//           <FormButtons>
//             <UnsubscribeForm.Button action="submit">
//               {i18n.ActionUnsubscribe}
//             </UnsubscribeForm.Button>
//             <UnsubscribeForm.Button onClick={() => setChangingEmail('true')}>
//               {i18n.ActionChangeEmail}
//             </UnsubscribeForm.Button>
//           </FormButtons>
//         </>,
//       )}
//     </>
//   );
// };
