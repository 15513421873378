import { map, type DataNode } from '@donkeyjs/proxy';

interface RelatedArticlesProps {
  articles: { article: DataNode<DataSchema, 'Article'>; $: any }[];
}

export function RelatedArticles(props: RelatedArticlesProps) {
  return map(
    () => props.articles,
    (rel) => <rel.$.article />,
  );
}
