import { session, type ClientSchemaMeta } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';

import { PhPenNib } from '@donkeyjs/phosphor-icons';
import { formatComposition } from './helpers';
import { MuBassClef } from './icons';

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Composer: {
    asString: (node) => formatName(node, { lastNameFirst: true }),
    icon: () => PhPenNib,
  },

  Composition: {
    asString: (node) =>
      [
        node.composer && formatName(node.composer),
        formatComposition(node, session.app.schema),
      ]
        .filter((s) => !!s)
        .join(' - '),
    icon: () => MuBassClef,
  },

  ProgramElement: {
    asString: (node) =>
      node.interval
        ? 'Interval'
        : node.composition
          ? [
              node.composition.composer &&
                formatName(node.composition.composer),
              formatComposition(node.composition, session.app.schema),
            ]

              .filter((s) => !!s)
              .join(' - ')
          : '',
  },
};
