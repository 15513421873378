import { bind, jsxx, type DomText } from '@donkeyjs/jsx-runtime';
import {
  map,
  store,
  type DataList,
  type MarkupEntity,
  type MarkupStringProcessor,
  type MarkupTreeEntity,
  type MarkupTreeType,
} from '@donkeyjs/proxy';
import debounce from 'debounce';
import { getMailContext } from '../../mail';
import { MarkupField } from './MarkupField';
import { MarkupLink } from './MarkupLink';
import type { MarkupInterface } from './interfaces/singleBlockMarkupInterface';

export interface MarkupTreeProps {
  nodeMap?: WeakMap<Node | DomText, MarkupTreeEntity>;
  entityMap?: WeakMap<MarkupTreeEntity, Node | DomText>;
  links?: DataList<DataSchema, 'BlockLink'>;
  readonly processor: MarkupStringProcessor;
  readonly tree: MarkupTreeType;
  readonly readonly?: boolean;
  readonly interface: MarkupInterface;
  readonly selectedEntities: MarkupEntity[];
  onupdated?(): void;
}

export function MarkupTree(props: MarkupTreeProps) {
  const mail = getMailContext();

  const nodeMap = new WeakMap();
  const entityMap = new WeakMap();

  const updated = debounce(() => {
    props.onupdated?.();
  }, 0);

  const empty = { empty: true } as const;

  props.nodeMap = nodeMap;
  props.entityMap = entityMap;
  return map<MarkupTreeEntity | { empty: true }, any>(
    () => (props.tree.length ? props.tree : [empty]),
    (e): JSX.Children => {
      if ('empty' in e) {
        return '\u200B';
      }

      const bindNode = (node: Node | DomText) => {
        props.nodeMap!.set(node, e);
        props.entityMap!.set(e, node);
        updated();
        return () => {
          props.nodeMap!.delete(node);
          props.entityMap!.delete(e);
        };
      };

      if (e.kind === 'formatting') {
        return e.entity.f === 'b' ? (
          <strong onmount={bindNode}>
            {jsxx(
              MarkupTree,
              store.clone(props, {
                get tree() {
                  return e.children;
                },
              }),
            )}
          </strong>
        ) : e.entity.f === 'i' ? (
          <em onmount={bindNode}>
            {jsxx(
              MarkupTree,
              store.clone(props, {
                get tree() {
                  return e.children;
                },
              }),
            )}
          </em>
        ) : e.entity.f === 's' ? (
          <s onmount={bindNode}>
            {jsxx(
              MarkupTree,
              store.clone(props, {
                get tree() {
                  return e.children;
                },
              }),
            )}
          </s>
        ) : (
          jsxx(
            MarkupTree,
            store.clone(props, {
              get tree() {
                return e.children;
              },
            }),
          )
        );
      }
      if (e.kind === 'text') {
        if (mail?.target === 'mail') {
          const lines = e.text.split('\n');
          return lines.map((line, i) => (
            <>
              {line.length ? line : <>&nbsp;</>}
              {i < lines.length - 1 ? <br /> : null}
            </>
          ));
        }
        return e.text.length ? e.text : props.tree.length === 1 ? '\u200B' : '';
      }

      if (e.kind === 'link') {
        return (
          <MarkupLink
            entity={e.entity}
            links={props.links}
            readonly={bind(() => props.readonly)}
            selected={bind(() => props.selectedEntities.includes(e.entity))}
          >
            {jsxx(
              MarkupTree,
              store.clone(props, {
                get tree() {
                  return e.children;
                },
              }),
            )}
          </MarkupLink>
        );
      }

      if (e.kind === 'field') {
        return (
          <MarkupField
            entity={e.entity}
            readonly={bind(() => props.readonly)}
            selected={bind(() => props.selectedEntities.includes(e.entity))}
          />
        );
      }

      return jsxx(
        MarkupTree,
        store.clone(props, {
          get tree() {
            return e.children;
          },
        }),
      );
    },
  );
}
