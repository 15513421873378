import {
  MailSectionImageRow,
  getI18n,
  getTheme,
  session,
  type ViewProps,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { formatWorksBy } from '@donkeyjs/module-music';
import { store } from '@donkeyjs/proxy';
import styles from './ViewEventEmail.module.css';

export function ViewEventEmail(props: ViewProps<DataSchema, 'Event'>) {
  const i18n = getI18n();
  const theme = getTheme();

  const state = store({
    get linkTo() {
      return session.router.prependPathWithHostname(
        session.router.getPath({ node: props.node }),
      );
    },
    get formattedWorks() {
      const result = formatWorksBy(
        props.node.program.map((r) => r.composition!).filter(Boolean),
        {
          i18n,
          includeFirstNames: true,
        },
      );
      return result;
    },
  });

  return () =>
    props.readonly && props.node.hideFromNewsletters ? null : (
      <div
        style="margin-bottom: 16px"
        class={bind(() => [
          styles.event,
          props.class,
          { [styles.hidden]: props.node.hideFromNewsletters },
        ])}
        onmount={props.onmount}
      >
        <table width="100%" cellpadding="0" cellspacing="0">
          <MailSectionImageRow
            file={props.node.images[0]}
            ratio={1}
            imagePercentage={15}
            imageSizeOutlook={80}
            linkTo={bind(() => state.linkTo)}
          >
            <a
              class={[styles.heading, theme.class.link]}
              href={bind(() => state.linkTo)}
            >
              <strong>{() => props.node.toString()}</strong>
            </a>
            {() => !!props.node.name && <p>{props.node.name}</p>}
            <p>
              <em>{() => state.formattedWorks}</em>
            </p>
            <a
              class={[styles.more, theme.class.link]}
              href={bind(() => state.linkTo)}
            >
              Read More <span>&gt;</span>
            </a>
          </MailSectionImageRow>
        </table>
      </div>
    );
}
