import { getTheme, text } from '@donkeyjs/client';
import { bind, live, updateComponent } from '@donkeyjs/jsx-runtime';
import { PhSpinnerGap } from '@donkeyjs/phosphor-icons';
import { dontWatch } from '@donkeyjs/proxy';
import { fullName } from '../../helpers';
import { I18nShopping } from '../../i18n';
import { useCart } from '../useCart';
import styles from './PaymentForm.module.css';
import { usePayment } from './usePayment';

export interface PaymentFormSettings {
  formClass?: JSX.ClassNames;
  target?: string;
  secondButton?: JSX.Children;
}

export function PaymentForm(props: PaymentFormSettings) {
  const theme = getTheme();
  const cart = useCart();
  const payment = usePayment();

  live(() => {
    if (cart.order) {
      dontWatch(() => {
        payment.ensurePayment(props.target || 'order', {
          defaultValues:
            (props.target || 'order') === 'order'
              ? {
                  billingDetails: {
                    name: fullName({
                      firstName: cart.order?.billingFirstName,
                      lastName: cart.order?.billingLastName,
                      middleName: cart.order?.billingMiddleName,
                    }),
                    email: cart.order?.email || undefined,
                    address: {
                      city: cart.order?.billingCity || undefined,
                      country: cart.order?.billingCountryCode || undefined,
                      line1: cart.order?.billingAddressLine1 || undefined,
                      line2: cart.order?.billingAddressLine2 || undefined,
                      postal_code: cart.order?.billingPostalCode || undefined,
                    },
                  },
                }
              : undefined,
        });
      });
    }
  });

  payment.checkStatus();

  live(() => {
    if (payment.success) {
      cart.order = undefined;
    }
  });

  return (
    <>
      {() =>
        !!payment.message && (
          <div class={theme.class.boxInfo}>{payment.message}</div>
        )
      }
      {() =>
        payment.element && !payment.success ? (
          <form onsubmit={(e) => payment.submit(e)}>
            <div
              class={bind(() => props.formClass)}
              onmount={(el) => payment.element?.mount(el)}
            />
            <div class={styles.buttons}>
              <button
                type="submit"
                id="submit"
                class={[styles.button, theme.class.button, 'default']}
              >
                {() => payment.loading && <PhSpinnerGap />}
                <span>{text(I18nShopping, 'Cart.PayNow')}</span>
              </button>
              {props.secondButton}
            </div>
          </form>
        ) : null
      }
    </>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(PaymentForm, hot?.PaymentForm);
  });
}
