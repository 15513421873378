import {
  createView,
  getMailContext,
  getTheme,
  session,
} from '@donkeyjs/client';
import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { Detail } from '../components/Detail';
import { ListTile } from '../components/ListTile';
import { Tile } from '../components/Tile';
import { related } from '../components/related';
import styles from './ViewArticle.module.css';
import { ViewArticleEmail } from './ViewArticleEmail';
import { tilesOrListClass, tilesOrListModes } from './helpers';

export const ViewArticle = createView<DataSchema, 'Article'>(
  {
    name: () => 'Default View',
    dataContainerClass: tilesOrListClass,
    modes: tilesOrListModes,
    enableAsBlock: true,
  },

  function ViewArticle(props) {
    const theme = getTheme();
    const mail = getMailContext();

    return () =>
      props.selected ? (
        <Detail
          class={bind(() => props.class)}
          onmount={bind(() => props.onmount)}
          image={(ratio) => <props.node.$.images ratio={ratio} />}
          kicker={<props.node.$.kicker />}
          date={<props.node.$.publishedAt formatter="PPP" />}
          title={<props.node.$.headline />}
          subtitle={<props.node.$.lead />}
          related={related({ node: props.node })}
          buttons={props.node.projects.$((rel) => (
            <a
              class={theme.class.button}
              href={session.router.getPath(
                { route: 'current' },
                { query: { project: [rel.project.id] } },
              )}
            >
              {() => rel.project.name}
            </a>
          ))}
        >
          <props.node.$.body />
        </Detail>
      ) : mail ? (
        jsxx(ViewArticleEmail, props)
      ) : (
        <a
          class={bind(() => props.class)}
          href={session.router.getPath({ node: props.node })}
          onmount={props.onmount}
        >
          {() =>
            jsxx(props.mode?.key === 'list' ? ListTile : Tile, {
              card: true,
              get readonly() {
                return props.readonly;
              },
              image: (ratio) => <props.node.$.images ratio={ratio} />,
              kicker: <props.node.$.kicker />,
              date: <props.node.$.publishedAt formatter="MMM yyyy" />,
              title: <props.node.$.headline />,
              children: (
                <>
                  {() =>
                    props.node.lead
                      .map((l) => l.text)
                      .filter(Boolean)
                      .join(' ')
                  }
                  {() =>
                    !(props.mode?.key === 'list') && (
                      <span class={styles.more}>
                        {props.node.body[0]?.text}
                      </span>
                    )
                  }
                </>
              ),
            })
          }
        </a>
      );
  },
);
