import { updateComponent } from '@donkeyjs/jsx-runtime';
import { createSidebarContext } from './helpers/sidebarContext';
import { Body } from './layout/Body';
import { Header } from './layout/Header';
import './main.css';

export function Main() {
  createSidebarContext();
  return (
    <>
      <Header />
      <Body />
    </>
  );
}

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateComponent(Main, hot?.Main);
  });
}
