import type { DataNode } from '@donkeyjs/proxy';
import type { ParsedOrderItem } from '../shoppingSettings';

export function parseProductOrderItem(
  item: DataNode<DataSchema, 'OrderItem'>,
): Omit<ParsedOrderItem, 'origin'> | null {
  return item.productOption
    ? {
        key: 'productOption',
        orderable: item.productOption,
        orderables: item.productOption.product.options.map((option) => ({
          item: option,
          name: option.name,
        })),
        name: item.productOption?.product.name,
        option: item.productOption?.name,
        image: item.productOption?.product.images[0],
      }
    : null;
}
