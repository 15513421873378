import type { IconProps } from '../types';


export function PhCaretRight(props: IconProps): JSX.Element { 
  return <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="1em"
    height="1em"
    fill="currentColor"
    viewBox="0 0 256 256"
    >
      {props.children}
    <rect width="256" height="256" fill="none" />
  {(props.weight || 'regular') === 'bold' && <><path d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"/></>}{(props.weight || 'regular') === 'duotone' && <><path d="M176,128,96,208V48Z" opacity="0.2"/><path d="M181.66,122.34l-80-80A8,8,0,0,0,88,48V208a8,8,0,0,0,13.66,5.66l80-80A8,8,0,0,0,181.66,122.34ZM104,188.69V67.31L164.69,128Z"/></>}{(props.weight || 'regular') === 'fill' && <><path d="M181.66,133.66l-80,80A8,8,0,0,1,88,208V48a8,8,0,0,1,13.66-5.66l80,80A8,8,0,0,1,181.66,133.66Z"/></>}{(props.weight || 'regular') === 'light' && <><path d="M180.24,132.24l-80,80a6,6,0,0,1-8.48-8.48L167.51,128,91.76,52.24a6,6,0,0,1,8.48-8.48l80,80A6,6,0,0,1,180.24,132.24Z"/></>}{(props.weight || 'regular') === 'thin' && <><path d="M178.83,130.83l-80,80a4,4,0,0,1-5.66-5.66L170.34,128,93.17,50.83a4,4,0,0,1,5.66-5.66l80,80A4,4,0,0,1,178.83,130.83Z"/></>}{(props.weight || 'regular') === 'regular' && <><path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"/></>}
  </svg>
}