import type { ViewMode } from '@donkeyjs/client';
import { PhListBullets, PhSquaresFour } from '@donkeyjs/phosphor-icons';

export const tilesOrListClass = ({ mode }: { mode: ViewMode | undefined }) =>
  mode?.key === 'list' ? 'tiles-list' : 'tiles';

export const tilesOrListModes = [
  {
    key: 'tiles',
    name: () => 'Tiles View',
    icon: () => <PhSquaresFour weight="fill" />,
  },
  {
    key: 'list',
    name: () => 'List View',
    icon: () => <PhListBullets weight="bold" />,
  },
];
