import {
  NodeRoutingPlugin,
  createClientApp,
  getNow,
  session,
  type AppClientModule,
} from '@donkeyjs/client';
import { clientModuleEvents } from '@donkeyjs/module-events';
import { clientModuleGallery } from '@donkeyjs/module-gallery';
import {
  clientModuleMailings,
  setMailingOptions,
} from '@donkeyjs/module-mailings';
import { clientModuleMusic } from '@donkeyjs/module-music';
import {
  clientModuleProducts,
  clientModuleShopping,
  parseProductOrderItem,
  setShoppingSettings,
} from '@donkeyjs/module-shopping';
import { clientModuleSocialButtons } from '@donkeyjs/module-social-buttons';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { clientSchemaMeta } from '../clientSchemaMeta';
import { schemaMeta } from '../schemaMeta';
import { settings } from '../settings';
import { Main } from './Main';
import { blocks } from './blocks';
import { theme } from './theme';
import { views } from './views';

setShoppingSettings({
  stripe: {
    publicKey: 'pk_test_gr73VjxBSaBJEmPPucRwBwkE',
  },
  parseOrderItem: (item) => parseProductOrderItem(item)!,
});

setMailingOptions({
  newsletterTemplates: [
    {
      id: 'default',
      name: 'Default',
      component: () =>
        import('../newsletter/Newsletter').then((m) => m.Newsletter),
    },
  ],
});

export const app = createClientApp({
  ...settings,

  schemaMeta,
  clientSchemaMeta,

  theme,
  views,
  blocks,

  tz: {
    utcToZonedTime,
    zonedTimeToUtc,
    defaultTimeZone: 'Europe/London',
  },

  root: Main,
  routerPlugins: [NodeRoutingPlugin],
  backOffice: () => import('./backoffice/index').then((m) => m.backOffice),
  nodeRouting: {
    Event: [
      {
        routeKey: 'schedule',
        query: (node) =>
          session.router.route.node?.key === 'schedule'
            ? session.router.query
            : node.starts > getNow()
              ? undefined
              : {
                  range: [
                    `${node.starts.getFullYear()}-${
                      node.starts.getMonth() + 1
                    }`,
                  ],
                },
      },
    ],
  },

  modules: [
    clientModuleEvents,
    clientModuleGallery,
    clientModuleMailings,
    clientModuleMusic,
    clientModuleProducts as AppClientModule<DataSchema>,
    clientModuleShopping,
    clientModuleSocialButtons,
  ],

  changelog: {
    scope: 'simoncallaghan.com',
    log: () => import('../changelog').then((m) => m.log),
  },
});
