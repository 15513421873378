import {
  MailSectionImageRow,
  getTheme,
  session,
  type ViewProps,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import styles from './ViewArticleEmail.module.css';

export function ViewArticleEmail(props: ViewProps<DataSchema, 'Article'>) {
  const theme = getTheme();

  const state = store({
    get linkTo() {
      return session.router.prependPathWithHostname(
        session.router.getPath({ node: props.node }),
      );
    },
  });

  return (
    <div style="margin-bottom: 16px">
      <table width="100%" cellpadding="0" cellspacing="0">
        <MailSectionImageRow
          file={props.node.images[0]}
          ratio={1}
          imagePercentage={15}
          imageSizeOutlook={80}
          linkTo={bind(() => state.linkTo)}
        >
          <a
            class={[styles.heading, theme.class.link]}
            href={bind(() => state.linkTo)}
          >
            <strong class={styles.headline}>
              <props.node.$.headline />
            </strong>
          </a>
          <p class={styles.lead}>
            <props.node.$.lead />
          </p>
          <a
            class={[styles.more, theme.class.link]}
            href={bind(() => state.linkTo)}
          >
            Read More <span>&gt;</span>
          </a>
        </MailSectionImageRow>
      </table>
    </div>
  );
}
