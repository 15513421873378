import type { AppClientModule } from '@donkeyjs/client';
import type { InstallScript } from '@donkeyjs/core';
import { ViewGalleryFileRef } from './client/views/ViewGalleryFileRef';

export * from './client';
export * from './client/backoffice';
export * from './schema';

export const clientModuleGallery: AppClientModule = {
  views: {
    FileRef: {
      gallery: ViewGalleryFileRef,
    },
  },
};

export const __install: InstallScript<typeof import('.')> = [
  {
    action: 'add-schema-extensions',
    names: ['gallerySchemaExtension'],
  },
  {
    action: 'add-client-modules',
    names: ['clientModuleGallery'],
  },
];
