import { Comment, Fragment } from '@donkeyjs/jsx-runtime';
import {
  createHashGenerator,
  dontWatch,
  signal,
  store,
  watch,
} from '@donkeyjs/proxy';
import type { Dom, JSXVirtualNode } from './dom';
import { createJSXNode } from './dom-utils/createJSXNode';
import { createNullNode } from './dom-utils/createNullNode';
import { componentContext, type RenderContext } from './mount/mount';
import { setOnError } from './mount/onError';

export type Component<Props extends {} = {}> = ((
  props: Props,
) => JSX.Element) & {
  component?: (
    context: RenderContext,
    props: Props,
    nextSsrCandidate?: Node | null,
  ) => JSXVirtualNode;
  update?: (newComponent: any) => void;
};

export type ComponentProps<Props extends {}> = {
  [key in keyof Props]: Props[key];
};

export type ComponentImplementation<Props extends {}> = (
  props: Props,
  dom: Dom,
) => JSX.Children;

export interface ComponentReturnValue<Props extends {}> {
  value: JSX.Element[];
  context: RenderContext;
  updateProps: (newProps: Props) => void;
}

export const ensureComponentInstrumented = <Props extends {}>(
  component: Component<Props>,
) => {
  if (component.update) return;

  const current = signal(component);

  component.component = (context, props, nextSsrCandidate) => {
    try {
      let render = () =>
        dontWatch(() =>
          withContext(context, () =>
            createJSXNode(current.value(props), nextSsrCandidate),
          ),
        );

      if (import.meta.hot) {
        const component = current.value;
        if (!['Markup', 'MarkupTree', 'MarkupField'].includes(component.name)) {
          render = () =>
            withContext(context, () =>
              createJSXNode(
                {
                  __type: 'jsx-node',
                  tag: Fragment,
                  props: {},
                  children: [
                    {
                      __type: 'jsx-node',
                      tag: Comment,
                      props: {},
                      children: [component.name],
                    },
                    () => {
                      const result = dontWatch(() => component(props));
                      return result;
                    },
                    {
                      __type: 'jsx-node',
                      tag: Comment,
                      props: {},
                      children: [`/${component.name}`],
                    },
                  ],
                },
                nextSsrCandidate,
              ),
            );
        }
      }

      // if (typeof result === 'function') result = bindContext(result, context);

      return render();
    } catch (error) {
      return createNullNode();
    }
  };

  component.update = (newComponent: any) => {
    current.value = newComponent;
    newComponent.component = component.component;
    newComponent.update = component.update;
  };
};

export const updateComponent = <Props extends {}>(
  value: Component<Props>,
  newComponent: any | undefined,
) => {
  if (newComponent) {
    ensureComponentInstrumented(value);
    value.update!(newComponent);
  }
};

export const onMount = (fn: () => void | (() => void)) => {
  if (!componentContext.current)
    console.error("Can't call onMount outside of a mount tree.");
  else componentContext.current.onMount.push([fn, componentContext.current]);
};

export const onUnmount = (fn: () => void | (() => void)) => {
  if (!componentContext.current)
    console.error("Can't call onUnmount outside of a mount tree.");
  else componentContext.current.onUnmount.push(bindContext(fn));
};

export const setState = <T extends {}>(value: T) => {
  let result = value;
  if (!componentContext.current)
    console.error("Can't call setState outside of a mount tree.");
  else {
    result = store(result, {
      name: `${componentContext.current.component.name}.state`,
    });
    componentContext.current.state = result;
  }
  return result;
};

export const getState = <T extends {}>() => {
  if (!componentContext.current)
    throw new Error("Can't call getState outside of a mount tree.");
  return componentContext.current.state as T;
};

export const getSyncState = <T extends {}>(key: object, create: () => T) => {
  if (!componentContext.current)
    throw new Error("Can't call getSyncState outside of a mount tree.");

  componentContext.current.global['sync-state'] ??= new WeakMap();
  if (!componentContext.current.global['sync-state'].has(key)) {
    componentContext.current.global['sync-state'].set(
      key,
      store(create(), {
        name: `${componentContext.current.component.name}.sync-state`,
      }),
    );
  }

  return componentContext.current.global['sync-state'].get(key) as T;
};

export const setContext = <T>(key: string | symbol, value: T) => {
  if (!componentContext.current)
    console.error("Can't set context outside of a mount tree.");
  else componentContext.current.context[key] = value;
  return value;
};

export const getContext = <T>(key: string | symbol): T => {
  if (!componentContext.current)
    throw new Error("Can't get context outside of a mount tree.");

  return componentContext.current.context[key];
};

export const setGlobal = <T>(key: string | symbol, value: T) => {
  if (!componentContext.current)
    console.error("Can't set context outside of a mount tree.");
  else componentContext.current.global[key] = value;
};

export const getGlobal = <T>(
  key: string | symbol,
  defaultValue?: () => T,
  context: RenderContext | undefined = componentContext.current,
): T => {
  if (!context) throw new Error("Can't get context outside of a mount tree.");

  if (context.global[key] === undefined && defaultValue !== undefined)
    context.global[key] = defaultValue();

  return context.global[key];
};

export const getError = () => {
  if (!componentContext.current)
    throw new Error("Can't call getError outside of a mount tree.");

  const state = store({
    error: undefined as Error | undefined,
  });

  setOnError(
    bindContext((error) => {
      if (!state.error) {
        state.error = error;
      }
    }),
  );

  return state;
};

export const live = (
  fn: (initial?: boolean) => ((final?: boolean) => void) | void,
) => {
  if (!componentContext.current) {
    console.error("Can't call `live` outside of a mount tree.");
    return;
  }
  const { dispose } = watch(bindContext(fn));
  onUnmount(dispose);
};

export const preventDefault = <E extends Event, R>(
  arg: ((event: E) => R) | E,
) => {
  if (typeof arg === 'function')
    return (event: E) => {
      event.preventDefault();
      return arg(event);
    };

  arg.preventDefault();
};

export const preventOwnDefault = <E extends Event, R>(
  arg: ((event: E) => R) | E,
) => {
  if (typeof arg === 'function')
    return (event: E) => {
      if (event.target === event.currentTarget) event.preventDefault();
      return arg(event);
    };

  if (arg.target === arg.currentTarget) arg.preventDefault();
};

export const stopPropagation = <E extends Event, R>(
  arg: ((event: E) => R) | E,
) => {
  if (typeof arg === 'function')
    return (event: E) => {
      event.stopPropagation();
      return arg(event);
    };

  arg.stopPropagation();
};

export const stopImmediatePropagation = <E extends Event, R>(
  arg: ((event: E) => R) | E,
) => {
  if (typeof arg === 'function')
    return (event: E) => {
      event.stopImmediatePropagation();
      return arg(event);
    };

  arg.stopImmediatePropagation();
};

export const bindContext = <T extends (...args: any[]) => any>(
  fn: T,
  toContext?: RenderContext,
): T => {
  const context = toContext || componentContext.current;
  return context
    ? (((...args: any[]) => {
        const prev = componentContext.current;
        componentContext.current = context;
        const result = fn(...args);
        componentContext.current = prev;
        return result;
      }) as any)
    : fn;
};

export function withContext<Result>(
  context: RenderContext,
  fn: () => Result,
): Result {
  const prev = componentContext.current;
  componentContext.current = context;
  try {
    const result = fn();
    componentContext.current = prev;
    return result;
  } catch (error) {
    componentContext.current = prev;
    console.error(error);
    throw error;
  }
}

export let createMountHash = createHashGenerator();

export const resetMountHashGenerator = (seed?: string) => {
  createMountHash = createHashGenerator(seed);
};
