import { bindContext, type Component } from '@donkeyjs/jsx-runtime';
import { mergeClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { session } from '../session';

export const installBackoffice = () => {
  if (!session.app.backOffice) return;

  session.app
    .backOffice()
    .then(
      bindContext((plugins) => {
        const components: Component<{ readonly children?: JSX.Children }>[] =
          [];
        const installers: (() => void)[] = [];

        for (const plugin of plugins) {
          if (typeof plugin === 'function') {
            components.push(plugin);
          } else {
            if (plugin.install) installers.push(plugin.install);
            if (plugin.plugin) components.push(plugin.plugin);
            if (plugin.blockPresets) {
              session.app.blockPresets.push(...plugin.blockPresets);
            }
            if (plugin.clientSchemaMeta) {
              session.app.clientSchemaMeta = mergeClientSchemaMeta(
                session.app.clientSchemaMeta,
                plugin.clientSchemaMeta,
              );
            }
            if (plugin.routeProperties) {
              session.app.routeProperties = plugin.routeProperties;
            }
          }
        }
        for (const installer of installers) {
          installer();
        }
        session.app.addPlugins(...components);
      }),
    )
    .catch((err) => {
      console.error('An error occurred while installing the backoffice.', err);
    });
};
