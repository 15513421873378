import { WithHeading, createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import styles from './ViewProgram.module.css';

export const ViewProgram = createView<DataSchema, 'Program'>(
  {
    name: () => 'Default View',
  },

  (props) => {
    return (
      <div
        class={bind(() => ['box with-padding', styles.box, props.class])}
        onmount={props.onmount}
      >
        <WithHeading heading={<props.node.$.title />} styleAs="subtitle">
          <props.node.$.notes />
          <props.node.$.elements />
        </WithHeading>
      </div>
    );
  },
);
