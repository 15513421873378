import { Loader, WithHeading, masonry } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta } from '@donkeyjs/proxy';
import { RelatedArticles } from './RelatedArticles';
import { RelatedEmbeds } from './RelatedEmbeds';
import { RelatedEvents } from './RelatedEvents';
import { RelatedProducts } from './RelatedProducts';
import { RelatedReviews } from './RelatedReviews';

export const related = (props: {
  node: any;
  plain?: boolean;
}): [() => number, JSX.Children] => {
  const count = () => {
    const result = [
      props.node.products?.length,
      props.node.articles?.length,
      props.node.events?.filter((ev: any) => ev.event.ends < new Date()).length,
      props.node.embeds?.length,
      props.node.reviews?.length,
    ].filter(Boolean);
    return result.reduce((a, b) => a! + b!, 0)!;
  };

  return [
    count,
    <Loader
      loading={bind(
        () =>
          meta(props.node.products)?.isLoading ||
          meta(props.node.articles)?.isLoading ||
          meta(props.node.events)?.isLoading ||
          meta(props.node.embeds)?.isLoading ||
          meta(props.node.reviews)?.isLoading,
      )}
      type="none"
    >
      {() =>
        count() > 0 && (
          <aside class="related">
            <WithHeading heading="Related" styleAs="heading1">
              <div
                class="tiles auto-height"
                onmount={props.plain ? undefined : (el) => masonry(el, '*')}
              >
                {!!props.node.products && (
                  <RelatedProducts products={props.node.products!} />
                )}
                {!!props.node.events && (
                  <RelatedEvents events={props.node.events!} />
                )}
                {!!props.node.articles && (
                  <RelatedArticles articles={props.node.articles!} />
                )}
                {!!props.node.embeds && (
                  <RelatedEmbeds embeds={props.node.embeds!} />
                )}
                {!!props.node.reviews && (
                  <RelatedReviews reviews={props.node.reviews!} />
                )}
              </div>
            </WithHeading>
          </aside>
        )
      }
    </Loader>,
  ];
};
