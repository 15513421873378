import { Loader, getTheme, text } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCheck, PhShoppingCartSimple } from '@donkeyjs/phosphor-icons';
import { meta, store } from '@donkeyjs/proxy';
import { I18nShopping } from '../../i18n';
import { useCart, type OrderableItem, type OrderableKey } from '../useCart';

interface AddToCartButtonProps<Key extends OrderableKey> {
  kind: Key;
  item: OrderableItem<Key>;
  amount?: number;
  class?: JSX.ClassNames;
  style?: 'default' | 'icon-only';
}

export function AddToCartButton<Key extends OrderableKey>(
  props: AddToCartButtonProps<Key>,
) {
  const theme = getTheme();
  const cart = useCart();

  meta(props.item).request({
    currency: true,
    name: true,
    price: true,
    weight: true,
  } as any);

  const state = store({
    get inCart() {
      return !!cart.countItems(props.kind, props.item);
    },
  });

  const onClick = () => {
    cart.add(props.kind, props.item, props.amount);
  };

  return () => (
    <Loader loading={bind(() => meta(props.item).isLoading)} type="none">
      <button
        type="button"
        class={bind(() => [
          theme.class.button,
          'add-to-cart-button',
          'default',
          props.class,
          { 'in-cart': state.inCart },
        ])}
        onclick={onClick}
      >
        {() =>
          state.inCart ? (
            <PhCheck weight="fill" />
          ) : (
            <PhShoppingCartSimple weight="fill" />
          )
        }
        {() =>
          props.style !== 'icon-only' && (
            <span>
              {() =>
                state.inCart
                  ? text(I18nShopping, 'ShopOption.IsInCart')
                  : text(I18nShopping, 'ShopOption.AddToCart')
              }
            </span>
          )
        }
      </button>
    </Loader>
  );
}
