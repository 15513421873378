import { createView } from '@donkeyjs/client';
import type { ProductsSchema } from '../../../schema';

export const ViewProductOptionPropertiesList = createView<
  ProductsSchema,
  'ProductOption'
>(
  { name: () => 'Properties List' },

  (props) => {
    return (
      <div class={props.class}>
        <props.node.$.name />
        <props.node.$.price />
        <props.node.$.weight />
      </div>
    );
  },
);
