import type { DataNode } from '@donkeyjs/proxy';
import type {
  Appearance,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import type { OrderableItem, OrderableKey } from '.';

export interface ParsedOrderItem {
  key: OrderableKey;
  orderable: OrderableItem<OrderableKey>;
  orderables: { item: OrderableItem<OrderableKey>; name: JSX.Children }[];
  name: JSX.Children;
  option: JSX.Children;
  image?: DataNode<DataSchema, 'FileRef'>;
  maxQuantity?: number;
  origin: DataNode<DataSchema, 'OrderItem'>;
}

export type ParseOrderItem = (
  item: DataNode<DataSchema, 'OrderItem'>,
) => Omit<ParsedOrderItem, 'origin'> | null | undefined;

export type ParseOrderable = (orderable: OrderableItem<OrderableKey>) => {
  name: JSX.Children;
};
export interface ShoppingClientSettings {
  stripe?: {
    publicKey: string;
    appearance?: Appearance;
    elementOptions?: StripePaymentElementOptions;
  };
  parseOrderItem?: ParseOrderItem;
  parseOrderable?: ParseOrderable;
  maxItemQuantity?: (item: OrderableItem<OrderableKey>) => number;
}

let settings: ShoppingClientSettings = {};

export const setShoppingSettings = (options: ShoppingClientSettings) => {
  settings = options;
};

export const getShoppingSettings = () => settings;
