import type { ClientSchemaMeta } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import {
  PhAddressBook,
  PhEnvelopeSimple,
  PhPenNib,
  PhUser,
} from '@donkeyjs/phosphor-icons';

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Newsletter: {
    asString: (node) => node.subject,
    icon: (node) => (node?.shipments?.length ? PhEnvelopeSimple : PhPenNib),
  },

  MailingList: {
    asString: (node) => node.name,
    icon: () => PhAddressBook,
  },

  Subscription: {
    asString: (node) => formatName(node, { lastNameFirst: true }) || node.email,
    icon: () => PhUser,
  },
};
