import type { SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  Article: {
    searchVectors: {
      kicker: 'A',
      headline: 'A',
      'lead.text': 'B',
      'body.text': 'C',
    },
  },
  ArticleEvents: {
    join: { article: 'event', event: 'article' },
  },
  ArticleProducts: {
    join: { article: 'product', product: 'article' },
  },
  ArticleReviews: {
    join: { article: 'review', review: 'article' },
  },
  ArticleProjects: {
    join: { article: 'project', project: 'article' },
  },
  ArticleEmbeds: {
    join: { article: 'embed', embed: 'article' },
  },
  CompositionTag: {
    join: { composition: 'tag', tag: 'composition' },
  },
  EmbedProjects: {
    join: { embed: 'project', project: 'embed' },
  },
  EmbedTags: {
    join: { embed: 'tag', tag: 'embed' },
  },
  Event: {
    searchVectors: {
      name: 'A',
      venueName: 'B',
      'venue.name': 'B',
      'info.text': 'B',
      'moreInfo.text': 'C',
    },
  },
  EventEmbeds: {
    join: { event: 'embed', embed: 'event' },
  },
  EventProducts: {
    join: { event: 'product', product: 'event' },
  },
  EventProjects: {
    join: { event: 'project', project: 'event' },
  },
  EventReviews: {
    join: { event: 'review', review: 'event' },
  },
  Product: {
    searchVectors: {
      name: 'A',
      'info.text': 'B',
      'moreInfo.text': 'C',
      'options.name': 'D',
    },
  },
  ProductProjects: {
    join: { product: 'project', project: 'product' },
  },
  ProductReviews: {
    join: { product: 'review', review: 'product' },
  },
  ProgramProjects: {
    join: { program: 'project', project: 'program' },
  },
  Review: {
    searchVectors: {
      'quote.text': 'A',
      publisher: 'B',
      'text.text': 'C',
    },
  },
};
