import { session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Logo.module.css';

interface LogoProps {
  class?: JSX.ClassNames;
}

export function Logo(props: LogoProps) {
  const color = () =>
    (session.router.route.node?.logo || 'LIGHT').toLowerCase() as
      | 'light'
      | 'dark';
  const placement = () =>
    (session.router.route.node?.logoPlacement || 'RIGHT_TOP')
      .toLowerCase()
      .split('_') as ('left' | 'center' | 'right' | 'top' | 'bottom')[];

  // const outline = admin.useOutline({
  //   name: 'Logo',
  //   icon: () => <PhImage weight="fill" />,
  //   emphasis: 'high',
  //   preventFirstClick: true,
  //   properties: () => (
  //     <>
  //       <h2>
  //         <PhImage weight="duotone" />
  //         Logo
  //       </h2>
  //       {() => session.router.route.node?.$('logo', { label: 'Color' })}
  //       {() =>
  //         session.router.route.node?.$('logoPlacement', {
  //           label: 'Placement',
  //         })
  //       }
  //     </>
  //   ),
  //   contextMenu: () => [
  //     {
  //       label: 'Light',
  //       get checked() {
  //         return color() === 'light';
  //       },
  //       onclick() {
  //         if (session.router.route.node) {
  //           session.router.route.node.logo = 'LIGHT';
  //         }
  //       },
  //     },
  //     {
  //       label: 'Dark',
  //       get checked() {
  //         return color() === 'dark';
  //       },
  //       onclick() {
  //         if (session.router.route.node) {
  //           session.router.route.node.logo = 'DARK';
  //         }
  //       },
  //     },
  //   ],
  // });

  return (
    <a
      href="/"
      class={bind(() => [
        props.class,
        styles.logo,
        styles[color()],
        ...placement().map((p) => styles[p]),
      ])}
    >
      <svg
        viewBox="119.583 168.723 115.435 84.848"
        xmlns="http://www.w3.org/2000/svg"
      >
        <filter
          id="drop-shadow-filter"
          x="-500%"
          y="-500%"
          width="1000%"
          height="1000%"
        >
          <title>Drop Shadow</title>
          <feGaussianBlur
            in="SourceAlpha"
            stdDeviation="2"
            result="gaussian-blur-0"
          />
          <feOffset dx="1" dy="1" result="offset-0" in="gaussian-blur-0" />
          <feComponentTransfer result="offsetblur" in="offset-0">
            <feFuncA id="spread-ctrl" type="linear" slope="2" />
          </feComponentTransfer>
          <feFlood flood-color="rgba(0,0,0,0.2)" result="flood-0" />
          <feComposite
            in2="offsetblur"
            operator="in"
            in="flood-0"
            result="composite-0"
          />
          <feMerge result="merge-0">
            <feMergeNode in="composite-0" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <text
          style="fill: currentColor; font-size: 37px; font-weight: bold; letter-spacing: .05em; white-space: pre; filter: url(#drop-shadow-filter);"
          x="119.583"
          y="200"
        >
          SIMON
        </text>
        <text
          style="fill: currentColor; font-size: 22.3px; white-space: pre; filter: url(#drop-shadow-filter);"
          x="119.984"
          y="226"
        >
          CALLAGHAN
        </text>
        <text
          style="fill: currentColor; font-size: 11.5px; white-space: pre; filter: url(#drop-shadow-filter);"
          x="195"
          y="247"
        >
          pianist
        </text>
      </svg>
    </a>
  );
}
