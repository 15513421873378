import { getTheme } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { useAudio } from '@donkeyjs/module-audio';
import {
  PhCircleNotch,
  PhPause,
  PhPlay,
  PhSpeakerSimpleHigh,
} from '@donkeyjs/phosphor-icons';
import { meta, type DataNode } from '@donkeyjs/proxy';
import styles from './NestedAudioTrack.module.css';

interface NestedAudioTrackProps {
  track: DataNode<DataSchema, 'FileRef'>;
  playlist: DataNode<DataSchema, 'FileRef'>[];
}

export function NestedAudioTrack(props: NestedAudioTrackProps) {
  const audio = useAudio();
  const theme = getTheme();

  meta(props.track).request({ file: { id: true, fileExtension: true } });

  const state = () => audio.getTrackState(props.track);

  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault();
    audio.togglePlay({ playlist: props.playlist, track: props.track });
  };

  return (
    <span
      class={bind(() => [
        'audio-track',
        styles.audioTrack,
        { [styles.loading]: state() === 'loading' },
      ])}
    >
      <button
        type="button"
        class={theme.class.linkButton}
        onclick={handleClick}
      >
        {() => icons[state()]({ weight: 'duotone' })}
        {() => props.track.name}
      </button>
    </span>
  );
}

const icons = {
  idle: PhSpeakerSimpleHigh,
  loading: PhCircleNotch,
  paused: PhPlay,
  playing: PhPause,
};
