import { Loader } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { map, meta, type DataNode } from '@donkeyjs/proxy';

interface RelatedEventsProps {
  events: { event: DataNode<DataSchema, 'Event'>; $: any }[];
}

export function RelatedEvents(props: RelatedEventsProps) {
  return (
    <Loader
      loading={bind(() => meta(props.events as any)?.isLoading)}
      type="none"
    >
      {map(
        () => props.events,
        (rel) => (rel.event.ends < new Date() ? null : <rel.$.event />),
      )}
    </Loader>
  );
}
