import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { getI18n } from '../../../i18n/getI18n';
import { LoadingSingleLine } from '../../../loaders';
import { InlineString } from '../string/InlineString';
import { getNumberValue } from './numberHelpers';

export function InlineFloat(props: FieldRenderProps<'float'>) {
  const i18n = getI18n();

  const cloned = store.clone(props, {
    field: {
      ...props.field,
      get value() {
        return props.field.value?.toString() ?? '';
      },
      set value(value) {
        props.field.value = value === '' ? undefined : Number.parseFloat(value);
      },
    } as NodeFieldInfo<string, DataNode<DataSchema, NodeTypename<DataSchema>>>,
    onfocus(element: HTMLElement) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(element);
      selection!.removeAllRanges();
      selection!.addRange(range);
    },
  });

  return () => {
    if (props.field.loading) return <LoadingSingleLine size="medium" />;

    if (props.readonly) {
      const value = getNumberValue(
        props.field.value,
        props.formatter,
        i18n,
        props.readonly,
      );
      return props.class && props.field.value != null ? (
        <span class={props.class}>{value}</span>
      ) : (
        value
      );
    }

    return jsxx(InlineString, cloned);
  };
}
