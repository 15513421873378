import {
  Blocks,
  FileRef,
  Menu,
  MobileMenu,
  getUserContext,
  session,
} from '@donkeyjs/client';
import {
  addGlobalEventListener,
  bind,
  live,
  onUnmount,
  removeGlobalEventListener,
} from '@donkeyjs/jsx-runtime';
import { PhMagnifyingGlass } from '@donkeyjs/phosphor-icons';
import { meta, store } from '@donkeyjs/proxy';
import debounce from 'debounce';
import styles from './Header.module.css';
import { Logo } from './Logo';

export function Header() {
  const user = getUserContext();

  meta(session.router.app).request({
    defaultHeader: {
      id: true,
      file: {
        id: true,
        fileExtension: true,
        name: true,
        fileType: true,
        width: true,
        height: true,
        uploadStatus: true,
      },
      name: true,
    },
  });
  meta(session.router.route.node)?.request({
    header: {
      id: true,
      file: {
        id: true,
        fileExtension: true,
        name: true,
        fileType: true,
        width: true,
        height: true,
        uploadStatus: true,
      },
      name: true,
    },
  });

  const state = store({
    el: undefined as HTMLElement | undefined,
    ul: undefined as HTMLElement | undefined,
    pinned: false,
    search: session.router.query.query?.[0] ?? '',
    get editable() {
      return (
        !!session.router.route.node && user.canEdit(session.router.route.node)
      );
    },
  });

  const performSearch = debounce((search: string) => {
    if (search && search !== session.router.query.query?.[0])
      session.router.navigate(
        session.router.getPath(
          { routeKey: 'search-results' },
          { query: { query: [search] } },
        ),
      );
  }, 300);

  live(() => {
    performSearch(state.search);
  });

  live(() => {
    if (session.router.route.node?.key !== 'search-results') state.search = '';
  });

  const updateScroll = () => {
    if (state.el && (state.ul ??= state.el.getElementsByTagName('ul')[0]))
      state.pinned =
        window.scrollY - (state.el.clientHeight - state.ul.clientHeight) >= 0;
  };

  addGlobalEventListener('scroll', updateScroll);
  onUnmount(() => {
    removeGlobalEventListener('scroll', updateScroll);
  });

  return (
    <>
      <header
        class={bind(() => [
          styles.header,
          {
            home: session.router.route.node?.key === 'home',
            [styles.home]: session.router.route.node?.key === 'home',
          },
        ])}
        onmount={(el) => {
          state.el = el;
        }}
      >
        <div class={styles.sizer}>
          <MobileMenu class={styles.mobileMenu} />
          <FileRef
            value={bind(
              () => session.router.route.node?.header,
              (value) => {
                if (session.router.route.node) {
                  session.router.route.node.header = value;
                }
              },
            )}
            default={bind(() => session.router.app.defaultHeader?.file)}
            ratio={bind(() =>
              session.router.route.node?.key === 'home' ? 'adaptive' : 4,
            )}
            changeOnClick="button"
          />
          <Logo class={styles.logo} />
          <h1 class={styles.about}>
            {() =>
              session.router.route.node?.metaTitle || session.router.route.name
            }
          </h1>
          <div class={styles.about}>
            <Blocks segment="metaDescription" />
          </div>
          {() =>
            session.router.route.node?.key === 'home' && (
              <div class={styles.feature}>
                <Blocks segment="main" />
              </div>
            )
          }
          <nav class={bind(() => ({ [styles.pinned]: state.pinned }))}>
            <Menu />
            {/* <CartLink /> */}
            <span class={styles.search}>
              <input
                type="text"
                placeholder="Search"
                value={bind(state, 'search')}
                onfocus={(ev: FocusEvent) =>
                  (ev.target as HTMLInputElement).select()
                }
              />

              <PhMagnifyingGlass weight="duotone" />
            </span>
          </nav>
        </div>
      </header>
    </>
  );
}
