import { jsxx } from '@donkeyjs/jsx-runtime';
import { bind } from '@donkeyjs/proxy';
import type { BlockProps } from '..';
import { getI18n } from '../i18n/getI18n';
import { getLayoutContext, setLayout } from '../layout/layoutContext';
import { getNodeContext } from '../layout/nodeContext';
import styles from './NodeFieldBlock.module.css';

export interface NodeFieldBlockProps {
  layout?: 'html' | 'inline';
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export function NodeFieldBlock(props: BlockProps<NodeFieldBlockProps>) {
  return () => jsxx(NodeField, props);
}

function NodeField(props: BlockProps<NodeFieldBlockProps>) {
  const layoutContext = getLayoutContext();
  const context = getNodeContext();
  const i18n = getI18n(true);

  if (props.layout) {
    setLayout(
      [props.layout, layoutContext.wrap].filter(Boolean).join(':') as any,
    );
  }

  return () => {
    const field = props.block?.type?.split(':')[1];
    if (!field || !context?.fields.includes(field)) return null;

    const Field = (context.node?.$ as any)?.[field];
    if (Field)
      return (
        <div
          contenteditable="false"
          onmount={props.onmount}
          class={bind(() => props.class)}
        >
          <Field
            class={bind(() => (props.width ? `width-${props.width}` : ''))}
            readonly
          />
        </div>
      );

    if (context.readonly) return null;

    return (
      <div
        onmount={props.onmount}
        class={bind(() => [props.class, styles.template])}
      >
        {i18n.getFieldName(context.typename as any, field)}
      </div>
    );
  };
}
