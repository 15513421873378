import type { AppClientModule } from '@donkeyjs/client';
import type { AppServerModule } from '@donkeyjs/server';
import { clientSchemaMeta } from './clientSchemaMeta';
import { permissions } from './permissions';
import { schemaMeta } from './schemaMeta';

export * from './client/backoffice';
export * from './helpers';
export * from './i18n/enGB';
export * from './icons';
export * from './schema';

export const clientModuleMusic: AppClientModule = {
  permissions,
  schemaMeta,
  clientSchemaMeta,
};

export const serverModuleMusic: AppServerModule = {
  permissions,
  schemaMeta,
};
