import { formatName, getSchemaI18n } from '@donkeyjs/core';
import { meta, type Culture, type DataNode } from '@donkeyjs/proxy';

export const formatComposition = (
  composition: DataNode<DataSchema, 'Composition'>,
  schema: ApplicationSchema,
  culture?: Culture,
) => {
  if (!composition) return '';
  const catalogueSystem = composition.composer?.catalogueSystem;
  const i18n = getSchemaI18n(schema, culture ?? meta(composition).culture);
  const arranger = formatName(composition.arranger);
  return [
    [
      [
        composition.name,
        composition.number != null && `No. ${composition.number}`,
        composition.tonality &&
          `in ${i18n.getEnumValue('Tonality', composition.tonality)}`,
      ]
        .filter((s) => !!s)
        .join(' '),
      (composition.opusNumber || composition.opusNumberCardinal) &&
        `Op. ${[composition.opusNumber, composition.opusNumberCardinal]
          .filter((s) => !!s)
          .join(' No. ')}`,
      [
        composition.catalogueNumber != null && catalogueSystem,
        composition.catalogueNumber,
      ]
        .filter((s) => !!s)
        .join(' '),
    ]
      .filter((s) => !!s)
      .join(', '),
    composition.nickname && `‘${composition.nickname}’`,
    (composition.yearAsNumber || composition.yearAsText) &&
      `(${composition.yearAsText || composition.yearAsNumber?.toString()})`,
    arranger && `(arr. ${arranger})`,
  ]
    .filter((s) => !!s)
    .join(' ');
};
