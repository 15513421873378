import {
  Embed,
  WithHeading,
  createView,
  getTheme,
  session,
  text,
  useReadonly,
  type EmbedType,
  type ViewProps,
} from '@donkeyjs/client';
import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { PhCaretLeft } from '@donkeyjs/phosphor-icons';
import { ListTile } from '../components/ListTile';
import { Tile } from '../components/Tile';
import { related } from '../components/related';
import { useSidebar } from '../helpers/sidebarContext';
import styles from './ViewEmbed.module.css';
import { tilesOrListClass, tilesOrListModes } from './helpers';

export const ViewEmbed = createView<DataSchema, 'Embed'>(
  {
    name: () => 'Default View',
    dataContainerClass: tilesOrListClass,
    modes: tilesOrListModes,
  },

  function ViewEmbed(props) {
    useReadonly(() => !props.selected);

    return () =>
      props.selected ? (
        jsxx(Detail, props)
      ) : (
        <a href={props.href} onmount={props.onmount}>
          {() => {
            const embed = props.node.embed as EmbedType | undefined;
            return jsxx(props.mode?.key === 'list' ? ListTile : Tile, {
              get readonly() {
                return props.readonly;
              },
              image: () =>
                !!embed?.thumbnail_url && (
                  <img
                    class={styles.image}
                    alt="Thumbnail"
                    src={bind(() => embed.thumbnail_url)}
                  />
                ),
              get class() {
                return props.class;
              },
              media: true,
              title: <props.node.$.headline />,
            });
          }}
        </a>
      );
  },
);

export function Detail(props: ViewProps<DataSchema, 'Embed'>) {
  const theme = getTheme();

  useSidebar(
    <>
      <a
        class={bind(() => [theme.class.button, 'default', styles.default])}
        href={session.router.getPath({ route: 'current' })}
      >
        <PhCaretLeft weight="bold" />
        <span>{text('Common.BackToList')}</span>
      </a>
      {related({ node: props.node, plain: true })[1]}
    </>,
  );

  return (
    <article
      class={bind(() => [styles.embed, props.class])}
      onmount={props.onmount}
    >
      <div class={styles.frame}>
        <Embed embed={bind(() => props.node.embed as any)} />
      </div>
      <div class="box with-padding">
        <WithHeading heading={<props.node.$.headline />} styleAs="title">
          <props.node.$.info />
        </WithHeading>
      </div>
    </article>
  );
}
