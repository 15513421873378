import { createView, getTheme, masonry, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store } from '@donkeyjs/proxy';
import styles from './ViewReview.module.css';

export const ViewReview = createView<DataSchema, 'Review'>(
  {
    name: () => 'Default View',
    dataContainerClass: () => 'tiles auto-height',
    onDataContainerMount: () => masonry,
    enableAsBlock: true,
  },

  function ViewReview(props) {
    const theme = getTheme();

    const state = store({
      get fontSize() {
        return `${
          1.2 +
          (200 -
            Math.min(
              props.node.quote.reduce(
                (length, block) => length + (block.text?.length || 0),
                0,
              ),
              200,
            )) /
            170
        }em`;
      },
    });

    meta(props.node).request({ publishedAt: true, showInSlideShow: true });

    return (
      <div
        class={bind(() => [styles.review, props.class, 'box'])}
        onmount={props.onmount}
      >
        {props.node.products.$((rel) => (
          <div
            class={styles.product}
            style={bind(() =>
              meta(props.node.products).isLoading ? 'display: none' : undefined,
            )}
          >
            <div class={styles.productImage}>
              <rel.product.$.images ratio={1} />
            </div>
            <div class={styles.productName}>
              About{' '}
              <a
                class={theme.class.link}
                href={session.router.getPath({ node: rel.product })}
              >
                {() => `${rel.product.name}:`}
              </a>
            </div>
          </div>
        ))}
        {props.node.events.$((rel) => (
          <div
            class={styles.product}
            style={bind(() =>
              meta(props.node.products).isLoading ? 'display: none' : undefined,
            )}
          >
            <div class={styles.productImage}>
              <rel.event.$.images ratio={1} />
            </div>
            <div class={styles.productName}>
              <a
                class={theme.class.link}
                href={session.router.getPath({ node: rel.event })}
              >
                {() => `${rel.event.toString()}:`}
              </a>
            </div>
          </div>
        ))}
        <div
          class={styles.quote}
          style={bind(() => `font-size: ${state.fontSize}`)}
        >
          <props.node.$.quote />
        </div>
        <div class={styles.publisher}>
          <div class={styles.logo}>
            <props.node.$.publisherLogo />
          </div>
          <div class={styles.info}>
            <p>
              <props.node.$.publisher />
            </p>
            {() =>
              !props.node.hideDate && (
                <span class={styles.date}>
                  <props.node.$.publishedAt formatter=" MMM yyy" />
                </span>
              )
            }
            {() =>
              !!props.node.linkToUrl && (
                <a
                  class={[theme.class.link, styles.source]}
                  href={props.node.linkToUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source
                </a>
              )
            }
          </div>
        </div>
      </div>
    );
  },
);
