export async function selectOSFiles(options?: { multiple?: boolean }) {
  return new Promise<FileList>((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = options?.multiple ?? false;
    input.style.display = 'none';

    input.onchange = (ev) => resolve((ev.target as HTMLInputElement).files!);

    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  });
}
