import { usePopper } from '@donkeyjs/client';
import { bind, onMount } from '@donkeyjs/jsx-runtime';
import {
  PhPause,
  PhPlay,
  PhSkipBack,
  PhSkipForward,
} from '@donkeyjs/phosphor-icons';
import { store } from '@donkeyjs/proxy';
import styles from './AudioPlayerButton.module.css';
import { AudioPlaylist } from './AudioPlaylist';
import { useAudio } from './useAudio';

const circumference = 52 * 2 * Math.PI;

interface AudioPlayerButtonProps {
  floating?: boolean;
}

export function AudioPlayerButton(props: AudioPlayerButtonProps) {
  const audio = useAudio();

  const state = store({ bottom: '5%', right: '5%', percentage: 0 });

  onMount(() => {
    const handle = setInterval(() => {
      if (audio.duration) {
        state.percentage =
          Math.round((audio.location / audio.duration) * 10000) / 100;
      }
    }, 1000 / 60);
    return () => clearInterval(handle);
  });

  const playlistPopper = usePopper(AudioPlaylist);
  const controlsPopper = usePopper(Controls);

  const handleMouseEnter = (ev: MouseEvent) => {
    playlistPopper.show(
      ev.target as HTMLElement,
      {},
      {
        placement: 'top-end',
        parent: ev.target as HTMLElement,
      },
    );
    controlsPopper.show(
      ev.target as HTMLElement,
      {},
      { placement: 'left', parent: ev.target as HTMLElement },
    );

    const handleMouseLeave = () => {
      playlistPopper.hide();
      controlsPopper.hide();
      (ev.target as HTMLElement).removeEventListener(
        'mouseleave',
        handleMouseLeave,
      );
    };

    (ev.target as HTMLElement).addEventListener('mouseleave', handleMouseLeave);
  };

  const progressOffset = () =>
    circumference -
    ((audio.state === 'loading' ? 90 : state.percentage) / 100) * circumference;

  return () =>
    !!(audio.currentTrack || audio.playlist.length) && (
      <div
        class={bind(() => [
          styles.player,
          { [styles.floating]: props.floating },
        ])}
        style={bind(() =>
          props.floating
            ? { bottom: state.bottom, right: state.right }
            : undefined,
        )}
        onmouseenter={handleMouseEnter}
      >
        <button type="button" class="button" onclick={() => audio.togglePlay()}>
          {() =>
            audio.currentTrack && (
              <svg class={[styles.progress, audio.state]} viewBox="0 0 120 120">
                <title>Progress</title>
                <circle
                  style={bind(() => ({
                    'stroke-dashoffset': progressOffset(),
                    'stroke-dasharray': `${circumference} ${circumference}`,
                  }))}
                  stroke-width="8"
                  stroke="white"
                  fill="transparent"
                  r="52"
                  cx="60"
                  cy="60"
                />
              </svg>
            )
          }
          {audio.state === 'idle' || audio.state === 'paused' ? (
            <PhPlay weight="fill" />
          ) : (
            <PhPause weight="fill" />
          )}
        </button>
      </div>
    );
}

function Controls() {
  const audio = useAudio();

  return () =>
    !audio.previousTrack && !audio.nextTrack ? null : (
      <div class={styles.controls}>
        {() =>
          audio.previousTrack && (
            <button
              type="button"
              class={styles.controlsButton}
              onclick={audio.playPrevious}
            >
              <PhSkipBack weight="fill" />
            </button>
          )
        }
        {() =>
          audio.nextTrack && (
            <button
              type="button"
              class={styles.controlsButton}
              onclick={audio.playNext}
            >
              <PhSkipForward weight="fill" />
            </button>
          )
        }
      </div>
    );
}
