import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { getI18n } from '../../../i18n/getI18n';
import { HtmlStringField } from '../string/HtmlString';
import { getNumberValue } from './numberHelpers';

export function HtmlFloat(props: FieldRenderProps<'float'>) {
  const i18n = getI18n();

  return jsxx(
    HtmlStringField,
    store.clone(props, {
      field: {
        ...props.field,
        get value(): string {
          return getNumberValue(
            props.field.value,
            props.formatter,
            i18n,
            props.readonly,
          );
        },
        set value(value) {
          props.field.value =
            value === '' ? undefined : Number.parseFloat(value);
        },
      } as NodeFieldInfo<
        string,
        DataNode<DataSchema, NodeTypename<DataSchema>>
      >,
    }),
  );
}
