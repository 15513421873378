import { Loader, createView, session } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import { formatComposition } from '@donkeyjs/module-music';
import { meta } from '@donkeyjs/proxy';
import { AddProgramElement } from './AddProgramElement';
import styles from './ViewProgramElement.module.css';

export const ViewProgramElement = createView<DataSchema, 'ProgramElement'>(
  {
    name: () => 'Default View',
    renderAdd: AddProgramElement,
  },

  function ViewProgramElement(props) {
    return (
      <div
        class={bind(() => [
          styles.element,
          props.class,
          { interval: props.node.interval },
        ])}
        onmount={props.onmount}
      >
        {() =>
          props.node.interval
            ? '~ Interval ~'
            : props.node.composition
              ? props.node.composition.composer && (
                  <>
                    <strong>
                      <Loader
                        loading={bind(
                          () =>
                            meta(props.node.composition!.composer!).isLoading,
                        )}
                      >
                        {() => formatName(props.node.composition!.composer!)}
                      </Loader>
                    </strong>
                    <Loader
                      loading={bind(
                        () =>
                          meta(props.node.composition!).getField('nickname')
                            .loading,
                      )}
                      type="inline-medium"
                    >
                      {() =>
                        formatComposition(
                          props.node.composition!,
                          session.app.schema,
                        )
                      }
                      {() =>
                        props.readonly && !props.node.note?.length ? null : (
                          <>
                            {' '}
                            <span
                              class={bind(() => [
                                'note',
                                { [styles.empty]: !props.node.note },
                              ])}
                            >
                              (<props.node.$.note layout="inline" />)
                            </span>
                          </>
                        )
                      }
                    </Loader>
                  </>
                )
              : null
        }
      </div>
    );
  },
);
