import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import { useImage } from '../components/useImage';

interface ImageRowProps {
  file:
    | DataNode<DataSchema, 'File'>
    | DataNode<DataSchema, 'FileRef'>
    | null
    | undefined;
  ratio?: number | 'adaptive' | undefined;
  imagePercentage: number;
  imageSizeOutlook: number;
  linkTo: string;
  class?: JSX.ClassNames;
  textStyle?: Partial<JSX.CSSProperties>;
  children?: JSX.Children;
}

export function MailSectionImageRow(props: ImageRowProps) {
  const textStyle: JSX.CSSProperties = Object.assign(
    { verticalAlign: 'top', paddingLeft: '12px' },
    props.textStyle,
  );

  const image = useImage({
    get mailWidth() {
      return props.imageSizeOutlook;
    },

    get file() {
      return props.file;
    },
    get ratio() {
      return props.ratio;
    },
  });

  return (
    <tr class={bind(() => props.class)}>
      <td
        width={bind(() => props.imageSizeOutlook)}
        style={bind(() => ({
          'vertical-align': 'top',
          width: `${props.imagePercentage}%`,
        }))}
      >
        {!!props.file && (
          <a href={bind(() => props.linkTo)}>
            <img
              alt={bind(() => image.file?.name)}
              src={bind(() => image.url)}
              width={bind(() => props.imageSizeOutlook)}
              style={bind(() => ({ display: 'block', width: '100%' }))}
            />
          </a>
        )}
      </td>
      <td
        align={bind(() => textStyle.textAlign || undefined)}
        style={bind(() => textStyle)}
      >
        {props.children}
      </td>
    </tr>
  );
}
