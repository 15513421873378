import { map, type DataNode } from '@donkeyjs/proxy';

interface RelatedEmbedsProps {
  embeds: { embed: DataNode<DataSchema, 'Embed'>; $: any }[];
}

export function RelatedEmbeds(props: RelatedEmbedsProps) {
  return map(
    () => props.embeds,
    (rel) => <rel.$.embed />,
  );
}
