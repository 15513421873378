import { formatName } from '@donkeyjs/core';
import type { ClientSchemaMeta } from './clientSchemaMetaUtils';

export const baseClientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Block: {
    asString: (node) => node.text?.toString() || '',
  },
  FileRef: {
    asString: (node) => node.name || node.file?.toString() || '',
  },
  Message: {
    asString: (node) =>
      [node.fromName || node.fromEmail, node.subject]
        .filter(Boolean)
        .join(': '),
  },
  Place: {
    asString: (node) =>
      [node.name, node.city, node.country].filter(Boolean).join(', '),
  },
  User: {
    asString: (node) => {
      const name = formatName(node);
      return name
        ? [name, node.email && `(${node.email})`].filter(Boolean).join(' ')
        : node.email;
    },
  },
};
