import {
  CheckboxList,
  WithHeading,
  getTheme,
  session,
  text,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode } from '@donkeyjs/proxy';
import { I18nMailingsAdmin } from '../../i18n';
import styles from './SendNewsletterForm.module.css';

export interface SendNewsletterFormProps {
  lists: DataNode<DataSchema, 'MailingList'>[];
}

export function SendNewsletterForm(props: SendNewsletterFormProps) {
  const theme = getTheme();
  const lists = session.data.getMailingLists({});
  const options = session.data.getSubscriptions({});

  return (
    <WithHeading
      styleAs={'title'}
      heading={text(I18nMailingsAdmin, 'Newsletter.SendHeading1')}
    >
      <p class={theme.class.paragraph}>
        {text(I18nMailingsAdmin, 'Newsletter.SendHeading2')}
      </p>
      <div class={styles.send}>
        <CheckboxList
          values={bind(props, 'lists')}
          options={bind(
            () =>
              options.aggregate?.lists?.values.map((option) => {
                const list = lists.find((l) => l.id === option.value?.id);
                return {
                  label: list?.name ?? '',
                  value: list!,
                  count: option.count,
                };
              }) ?? [],
          )}
        />
      </div>
    </WithHeading>
  );
}
