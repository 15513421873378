import { WithHeading } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretDown } from '@donkeyjs/phosphor-icons';
import styles from './ListTile.module.css';
import type { TileProps } from './Tile';

export function ListTile(props: TileProps) {
  return (
    <article
      class={bind(() => [
        props.class,
        styles.article,
        {
          [styles.overflow]: props.overflow,
          [styles.expandable]: props.expanded !== undefined,
          [styles.expanded]: props.expanded,
        },
      ])}
    >
      <div class={[styles.image, styles.box, 'box']}>
        {() => props.image?.(1)}
      </div>
      <div class={styles.info}>
        <div class={[styles.box, 'box']}>
          {() =>
            props.button && <div class={styles.button}>{props.button}</div>
          }
          {(props.kicker || props.date) && (
            <div class={styles.details}>
              {() =>
                props.kicker && (
                  <span class={styles.kicker}>{props.kicker}</span>
                )
              }
              {() =>
                props.date && <span class={styles.date}>{props.date}</span>
              }
            </div>
          )}
          <WithHeading heading={bind(() => props.title)}>
            {props.subtitle && (
              <div class={styles.subtitle}>{props.subtitle}</div>
            )}
            {props.children && <div class={styles.text}>{props.children}</div>}
          </WithHeading>
          {() =>
            props.expanded !== undefined &&
            !!props.linkTo && (
              <a class={styles.expander} href={bind(() => props.linkTo)}>
                <PhCaretDown weight="fill" />
              </a>
            )
          }
        </div>
        {props.append}
      </div>
    </article>
  );
}
