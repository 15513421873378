import type { SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  MailingListSubscription: {
    join: { list: 'subscription', subscription: 'list' },
  },
  Subscription: {
    searchVectors: {
      email: 'A',
      firstName: 'B',
      lastName: 'B',
    },
  },
};
