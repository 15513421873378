import { bind } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { List } from '../../../components/List';
import { useSortableList } from '../../../dragdrop/useSortable';
import { getViewContext } from '../../../views';
import { View } from '../../../views/View';
import { getView } from '../node/getView';

export function InlineNodeList(props: FieldRenderProps<'nodeList'>) {
  const viewContext = getViewContext();

  const state = store({
    get view() {
      return props.field.schema
        ? getView(props.field.schema.type, props.view, viewContext)
        : undefined;
    },
  });

  const sortable = props.field.value && useSortableList(props.field.value);

  return () => {
    if (state.view)
      return props.field.value?.length || !props.readonly ? (
        <div class={bind(() => props.class)} onmount={props.onmount}>
          <List
            data={props.field.value}
            render={(node) => {
              const sort = sortable?.(node as any);
              return (
                <View
                  node={node}
                  context={props.field.value}
                  view={state.view!}
                  readonly={bind(() => props.readonly)}
                  draggable={sort?.draggable}
                  accept={sort?.accept as any}
                />
              );
            }}
            renderAdd={bind(() => state.view?.renderAdd)}
            renderContainer={state.view?.renderContainer}
            renderDataContainer={state.view?.renderDataContainer}
            dataContainerClass={state.view?.dataContainerClass}
            class={state.view?.containerClass}
          />
        </div>
      ) : null;

    return (
      <div class="view" onmount={props.onmount}>
        {() =>
          `No view available for ${props.field.schema?.type} with key ${
            props.view ?? viewContext?.preferredListView
              ? `'${props.view ?? viewContext?.preferredListView}' or 'default'`
              : "'default'"
          }`
        }
      </div>
    );
  };
}
