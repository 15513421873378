import { onUnmount } from '@donkeyjs/jsx-runtime';
import { usePopper } from '../../helpers/usePopper';
import { getTheme } from '../../styles/applyTheme';
import styles from './usePopup.module.css';

export interface PopupProps {
  hasFocus?: boolean;
  children?: JSX.Children;
  onClose?: () => void;
}

export type Popup = ReturnType<typeof usePopup>;

export const usePopup = (props: PopupProps) => {
  const popper = usePopper(Popup);

  return {
    show: (target: HTMLElement) => {
      popper.show(target, props, { placement: 'bottom-start' });
    },
    hide: () => {
      popper.hide();
    },
    toggle: (target: HTMLElement) => {
      popper.toggle(target, props, { placement: 'bottom-start' });
    },
    get hasFocus() {
      return props.hasFocus;
    },
    get isVisible() {
      return popper.isVisible();
    },
  };
};

export function attachPopup(children: JSX.Children) {
  let element: HTMLElement | undefined;

  const popup = usePopup({
    children,
    onClose() {
      popup.hide();
      element?.focus();
    },
  });

  return (el: HTMLElement) => {
    element = el;

    function handleClick(e: MouseEvent) {
      e.preventDefault();
      popup.toggle(el);
    }

    function handleKeyDown(ev: KeyboardEvent) {
      ev.preventDefault();
      if (ev.key === 'Enter') popup.toggle(el);
    }

    el.addEventListener('click', handleClick);
    el.addEventListener('keydown', handleKeyDown);

    return () => {
      element = undefined;
      el.removeEventListener('click', handleClick);
      el.removeEventListener('keydown', handleKeyDown);
    };
  };
}

function Popup(props: PopupProps) {
  const focusin = (e: FocusEvent) => {
    if (e.target instanceof HTMLElement) {
      props.hasFocus = !!e.target.closest(`.${styles.popup}`);
      if (!props.hasFocus) {
        props.onClose?.();
      }
    }
  };

  document.addEventListener('focusin', focusin);

  onUnmount(() => {
    document.removeEventListener('focusin', focusin);
  });

  return (
    <div class={[styles.popup, getTheme().themeClass]}>{props.children}</div>
  );
}
