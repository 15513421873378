import type { I18nContext } from '@donkeyjs/client';
import { formatName } from '@donkeyjs/core';
import type { DataNode } from '@donkeyjs/proxy';
import { I18nMusic } from '../i18n';

export const formatWorksBy = (
  compositions: DataNode<DataSchema, 'Composition'>[] | undefined | null,
  {
    i18n,
    includeFirstNames,
  }: { i18n: I18nContext; includeFirstNames?: boolean },
) => {
  if (!compositions?.length) return null;

  const composersSet = new Set<string>();
  compositions.forEach((composition: any) => {
    if (composition.composer)
      composersSet.add(
        includeFirstNames
          ? formatName(composition.composer)
          : composition.composer.lastName,
      );
  });

  if (!composersSet.size) return null;

  const composers = Array.from(composersSet);
  const lastComposer = composers.pop();
  return `${i18n.get(I18nMusic, 'WorksBy')} ${[
    composers.join(', '),
    lastComposer,
  ]
    .filter((s) => !!s)
    .join(` ${i18n.get('Common.And')} `)}`;
};
