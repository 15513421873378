import { defaultPermissions } from '@donkeyjs/core';

export const permissions = defaultPermissions().with({
  visitor: {
    Article: { read: 'allow' },
    ArticleEmbeds: { read: 'allow' },
    ArticleEvents: { read: 'allow' },
    ArticleProducts: { read: 'allow' },
    ArticleProjects: { read: 'allow' },
    ArticleReviews: { read: 'allow' },
    Category: { read: 'allow' },
    Composer: { read: 'allow' },
    Composition: { read: 'allow' },
    Embed: { read: 'allow' },
    EventProducts: { read: 'allow' },
    EventProjects: { read: 'allow' },
    EventReviews: { read: 'allow' },
    Place: { read: 'allow' },
    ProductProjects: { read: 'allow' },
    ProductReviews: { read: 'allow' },
    ProgramProjects: { read: 'allow' },
    Project: { read: 'allow' },
    Review: { read: 'allow' },
    EventEmbeds: { read: 'allow' },
    CompositionTag: { read: 'allow' },
    EmbedProjects: { read: 'allow' },
    Program: { read: 'allow' },
  },
});
