import { createI18nLibrary } from '@donkeyjs/core';

export const I18nShopping = createI18nLibrary({
  'en-GB': () => import('./enGB').then((m) => m.EnGB),
  nl: () => import('./nlNL').then((m) => m.Nl),
});

export const I18nShoppingAdmin = createI18nLibrary({
  'en-GB': () => import('./enGB').then((m) => m.EnGBAdmin),
  nl: () => import('./nlNL').then((m) => m.NlAdmin),
});
