import {
  Heading,
  IncreaseHeadingLevel,
  getTheme,
  session,
  text,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretLeft } from '@donkeyjs/phosphor-icons';
import { store } from '@donkeyjs/proxy';
import { useSidebar } from '../helpers/sidebarContext';
import styles from './Detail.module.css';
import type { TileProps } from './Tile';

export interface DetailProps extends Exclude<TileProps, 'subtitle'> {
  date?: JSX.Children;
  lead?: JSX.Children;
  subtitle?: JSX.Children;
  sidebar?: JSX.Children;
  related?: [() => number, JSX.Children];
  buttons?: JSX.Children;
  prepend?: JSX.Children;
  onmount?: JSX.OnMount<HTMLElement>;
}

export function Detail(props: DetailProps) {
  const theme = getTheme();

  const state = store({
    get relatedCount() {
      return props.related?.[0]() || 0;
    },
  });

  useSidebar(
    <>
      <div class={[styles.buttons, styles.sideButtons]}>
        <a
          class={[theme.class.button, 'default']}
          href={session.router.getPath({ route: 'current' })}
        >
          <PhCaretLeft weight="bold" />
          <span>{text('Common.BackToList')}</span>
        </a>
      </div>
      {() =>
        state.relatedCount > 0 && state.relatedCount < 3 && props.related![1]
      }
    </>,
  );

  return (
    <article
      class={bind(() => [props.class, styles.container, 'detail-view'])}
      onmount={props.onmount}
    >
      <div class={styles.article}>
        {props.prepend}
        <div class={styles.title}>
          <Heading styleAs={'title'}>{props.title}</Heading>
          {() =>
            (props.date || props.subtitle) && (
              <div class={styles.subtitle}>
                {() =>
                  props.date && <div class={styles.date}>{props.date}</div>
                }
                {() => props.subtitle}
              </div>
            )
          }
          {() =>
            props.buttons && <div class={styles.buttons}>{props.buttons}</div>
          }
        </div>

        {() =>
          props.lead && (
            <div class={[styles.lead, 'box with-padding']}>
              <IncreaseHeadingLevel>{props.lead}</IncreaseHeadingLevel>
            </div>
          )
        }
        <IncreaseHeadingLevel>
          <div class={[styles.main, 'box with-padding']}>{props.children}</div>
        </IncreaseHeadingLevel>
        {() =>
          state.relatedCount > 2 && (
            <IncreaseHeadingLevel>
              {() => props.related![1]}
            </IncreaseHeadingLevel>
          )
        }
        {() => props.image?.(1)}
      </div>
    </article>
  );
}
