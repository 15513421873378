import { getContext, onUnmount, setContext } from '@donkeyjs/jsx-runtime';
import { dontWatch, store } from '@donkeyjs/proxy';

const key = Symbol();

interface SidebarContext {
  children: JSX.Children[];
  add: (children: JSX.Children) => void;
  remove: (children: JSX.Children) => void;
}

export const createSidebarContext = () => {
  const context = store<SidebarContext>({
    children: [],
    add(children: JSX.Children) {
      dontWatch(() => {
        context.children = [...context.children, children];
      });
    },
    remove(children: JSX.Children) {
      dontWatch(() => {
        context.children = context.children.filter((c) => c !== children);
      });
    },
  });
  setContext(key, context);
  return context;
};

export const getSidebar = () => getContext<SidebarContext>(key);

export const useSidebar = (sidebar: JSX.Children) => {
  const context = getSidebar();
  context.add(sidebar);
  onUnmount(() => context.remove(sidebar));
};
