import { bind, type MarkupFieldType } from '@donkeyjs/proxy';
import { getI18n } from '../../i18n/getI18n';
import { getNodeContext } from '../nodeContext';
import styles from './MarkupField.module.css';

interface MarkupFieldProps {
  entity: MarkupFieldType;
  readonly?: boolean;
  selected?: boolean;
}

export function MarkupField(props: MarkupFieldProps) {
  const context = getNodeContext();
  const i18n = getI18n(true);

  return () => {
    const field = props.entity.fld.split(':')[1];
    if (!field || !context?.fields.includes(field)) return null;

    const Field = (context.node?.$ as any)?.[field];
    if (Field)
      return props.readonly ? (
        <Field readonly />
      ) : (
        <span contenteditable="false">
          <Field readonly />
        </span>
      );

    if (props.readonly || context.readonly) return null;

    return (
      <span class={bind(() => [styles.template])} contenteditable="false">
        {i18n.getFieldName(context.typename as any, field)}
      </span>
    );
  };
}
