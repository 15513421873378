import type { AppClientModule } from '@donkeyjs/client';
import { permissions } from '../permissions';
import { clientSchemaMeta } from './clientSchemaMeta';
import { blocks } from './ui/blocks';

export * from './ui/blocks/SocialButtonsBlock';

export const clientModuleSocialButtons: AppClientModule = {
  blocks,
  permissions,
  clientSchemaMeta,
};
