import { getLocale } from '@donkeyjs/core';
import { meta, type DataNode } from '@donkeyjs/proxy';

export const formatEventStartsEnds = (
  event: DataNode<DataSchema, 'Event'>,
  options?: {
    includeYear?: boolean;
    timeDelimiter?: string;
  },
) => {
  const i18n = getLocale(meta(event).culture);
  const year = options?.includeYear ? ' yyyy' : '';
  const formatter = event.fullDay
    ? `do MMM${year}`
    : [`do MMM${year}`, options?.timeDelimiter || '', 'p']
        .filter(Boolean)
        .join(' ');

  const starts = i18n.formatDate(event.starts, formatter);
  let ends = i18n.formatDate(event.ends, formatter);
  ends = ends.slice(commonPart(ends, starts).length);
  return `${starts}${ends ? ` - ${ends}` : ''}`;
};

function commonPart(string1: string, string2: string) {
  let i = 0;
  while (
    i <= string1.length &&
    i <= string2.length &&
    string1[i] === string2[i]
  )
    i += 1;
  return i === 0 ? '' : string1.slice(0, i - 1);
}
