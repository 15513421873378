import { baseSchema, blocksSort } from '@donkeyjs/core';
import { schemaEnGB } from '@donkeyjs/i18n-en-gb';
import { eventsSchemaExtension } from '@donkeyjs/module-events';
import { gallerySchemaExtension } from '@donkeyjs/module-gallery';
import { mailingsSchemaExtension } from '@donkeyjs/module-mailings';
import { compositionsSort, musicSchemaExtension } from '@donkeyjs/module-music';
import {
  productsSchemaExtension,
  shoppingSchemaExtension,
} from '@donkeyjs/module-shopping';
import { socialButtonsSchemaExtension } from '@donkeyjs/module-social-buttons';
import { createSchema, extendSchema } from '@donkeyjs/proxy';
import { SchemaEnGB } from './i18n/enGB';

const { result } = extendSchema(baseSchema)
  .with({ i18n: { 'en-GB': schemaEnGB } })
  .with(eventsSchemaExtension)
  .with(musicSchemaExtension)
  .with(shoppingSchemaExtension)
  .with(productsSchemaExtension)
  .with(gallerySchemaExtension)
  .with(mailingsSchemaExtension)
  .with(socialButtonsSchemaExtension)
  .with({
    enums: {
      Logo: ['LIGHT', 'DARK'],
      LogoPlacement: [
        'LEFT_TOP',
        'LEFT_BOTTOM',
        'CENTER_TOP',
        'CENTER_BOTTOM',
        'RIGHT_TOP',
        'RIGHT_BOTTOM',
      ],
    },

    nodes: {
      Article: {
        publishedAt: 'date',
        starred: 'boolean?',
        kicker: ['string?', { i18n: true }],
        headline: ['string', { i18n: true }],
      },

      ArticleEvents: {
        article: ['Article', { reverse: 'events', cascadeDelete: true }],
        event: ['Event', { reverse: 'articles', cascadeDelete: true }],
      },

      ArticleEmbeds: {
        article: ['Article', { reverse: 'embeds', cascadeDelete: true }],
        embed: ['Embed', { reverse: 'articles', cascadeDelete: true }],
        sortEmbedIndex: 'float?',
      },

      ArticleProducts: {
        article: ['Article', { reverse: 'products', cascadeDelete: true }],
        product: ['Product', { reverse: 'articles', cascadeDelete: true }],
        sortProductIndex: 'float?',
      },

      ArticleProjects: {
        article: ['Article', { reverse: 'projects', cascadeDelete: true }],
        project: ['Project', { reverse: 'articles', cascadeDelete: true }],
        sortProjectIndex: 'float?',
      },

      ArticleReviews: {
        article: ['Article', { reverse: 'reviews', cascadeDelete: true }],
        review: ['Review', { reverse: 'articles', cascadeDelete: true }],
        sortReviewIndex: 'float?',
      },

      Category: {
        nodeType: 'string',
        name: ['string', { i18n: true }],
        sortIndex: 'float?',
      },

      Embed: {
        embed: ['json', { jsonType: 'embed' }],
        headline: ['string?', { i18n: true }],
        sortIndex: 'float?',
      },

      EmbedProjects: {
        embed: ['Embed', { reverse: 'projects', cascadeDelete: true }],
        project: ['Project', { reverse: 'embeds', cascadeDelete: true }],
      },

      EmbedTags: {
        tag: ['Tag', { reverse: 'embeds', cascadeDelete: true }],
        embed: ['Embed', { reverse: 'tags', cascadeDelete: true }],
      },

      EventEmbeds: {
        event: ['Event', { reverse: 'embeds', cascadeDelete: true }],
        embed: ['Embed', { reverse: 'events', cascadeDelete: true }],
        sortEmbedIndex: 'float',
      },

      EventReviews: {
        event: ['Event', { reverse: 'reviews', cascadeDelete: true }],
        review: ['Review', { reverse: 'events', cascadeDelete: true }],
      },

      EventProducts: {
        event: ['Event', { reverse: 'products', cascadeDelete: true }],
        product: ['Product', { reverse: 'events', cascadeDelete: true }],
        sortProductIndex: 'float?',
      },

      EventProjects: {
        event: ['Event', { reverse: 'projects', cascadeDelete: true }],
        project: ['Project', { reverse: 'events', cascadeDelete: true }],
      },

      ProductProjects: {
        product: ['Product', { reverse: 'projects', cascadeDelete: true }],
        project: ['Project', { reverse: 'products', cascadeDelete: true }],
      },

      ProductReviews: {
        product: ['Product', { reverse: 'reviews', cascadeDelete: true }],
        review: ['Review', { reverse: 'products', cascadeDelete: true }],
        sortReviewIndex: 'float?',
      },

      Program: {
        title: ['string?', { i18n: true }],
        segment: 'string?',
      },

      ProgramProjects: {
        program: ['Program', { reverse: 'projects', cascadeDelete: true }],
        project: ['Project', { reverse: 'programs', cascadeDelete: true }],
      },

      Project: {
        name: ['string', { i18n: true }],
      },

      Review: {
        publisher: ['string', { i18n: true }],
        publishedAt: 'date',
        linkToUrl: 'string',
        showInSlideShow: 'boolean?',
        showOnReviewsPage: 'boolean?',
        hideDate: 'boolean?',
        publisherLogo: ['FileRef?', { reverse: 'reviewPublisherLogo' }],
      },
    },

    extensions: {
      App: {
        add: {
          defaultHeader: ['FileRef?', { reverse: 'onAppDefaultHeader' }],
        },
      },

      Block: {
        add: {
          onArticleLead: [
            'Article?',
            { reverse: 'lead', sort: blocksSort, cascadeDelete: true },
          ],
          onArticleBody: [
            'Article?',
            {
              reverse: 'body',
              sort: blocksSort,
              cascadeDelete: true,
            },
          ],
          onEmbedInfo: [
            'Embed?',
            { reverse: 'info', sort: blocksSort, cascadeDelete: true },
          ],
          onEventInfo: [
            'Event?',
            { reverse: 'info', sort: blocksSort, cascadeDelete: true },
          ],
          onEventMoreInfo: [
            'Event?',
            { reverse: 'moreInfo', sort: blocksSort, cascadeDelete: true },
          ],
          onProductInfo: [
            'Product?',
            { reverse: 'info', sort: blocksSort, cascadeDelete: true },
          ],
          onProductMoreInfo: [
            'Product?',
            { reverse: 'moreInfo', sort: blocksSort, cascadeDelete: true },
          ],
          onComposerBiography: [
            'Composer?',
            { reverse: 'biography', sort: blocksSort, cascadeDelete: true },
          ],
          onComposerNotes: [
            'Composer?',
            { reverse: 'notes', sort: blocksSort, cascadeDelete: true },
          ],
          onCompositionMovements: [
            'Composition?',
            { reverse: 'movements', sort: blocksSort, cascadeDelete: true },
          ],
          onCompositionNotes: [
            'Composition?',
            { reverse: 'notes', sort: blocksSort, cascadeDelete: true },
          ],
          onCompositionInternalNotes: [
            'Composition?',
            { reverse: 'internalNotes', sort: blocksSort, cascadeDelete: true },
          ],
          onProgramNotes: [
            'Program?',
            { reverse: 'notes', sort: blocksSort, cascadeDelete: true },
          ],
          onProjectInfo: [
            'Project?',
            { reverse: 'info', sort: blocksSort, cascadeDelete: true },
          ],
          onReviewQuote: [
            'Review?',
            { reverse: 'quote', sort: blocksSort, cascadeDelete: true },
          ],
          onReviewText: [
            'Review?',
            { reverse: 'text', sort: blocksSort, cascadeDelete: true },
          ],
        },
      },

      Composition: {
        add: {
          segment: 'string?',
          category: ['Category?', { reverse: 'compositions' }],
        },
      },

      Event: {
        add: {
          kind: 'string?',
          venueName: ['string?', { i18n: true }],
          promotor: ['string?', { i18n: true }],
          linkToUrl: ['string?', { i18n: true }],
          linkToUrlText: ['string?', { i18n: true }],
          customRepertoireLabelPast: ['string?', { i18n: true }],
          customRepertoireLabelFuture: ['string?', { i18n: true }],
          hideFromNewsletters: 'boolean?',
        },
      },

      FileRef: {
        add: {
          article: ['Article?', { reverse: 'images', cascadeDelete: true }],
          compositionAudioTrack: [
            'Composition?',
            {
              embeddedList: true,
              reverse: 'audioTracks',
              cascadeDelete: true,
              sort: [
                {
                  name: 'AUTO',
                  order: ['sortIndex:auto'],
                },
              ],
            },
          ],
          event: ['Event?', { reverse: 'images', cascadeDelete: true }],
          program: ['Program?', { reverse: 'images', cascadeDelete: true }],
          reviewAttachment: [
            'Review?',
            { reverse: 'attachments', cascadeDelete: true },
          ],
        },
      },

      OrderItem: {
        add: {
          productOption: ['ProductOption?', { reverse: 'orderItems' }],
        },
      },

      Product: {
        add: {
          externalLinkUrl: 'string?',
          externalLinkLabel: ['string?', { i18n: true }],
          externalPrice: 'float?',
          downloadUrl: 'string?',
          segment: 'string?',
          streamUrl: 'string?',
        },
      },

      ProgramElement: {
        add: {
          event: [
            'Event?',
            {
              embeddedList: true,
              reverse: 'program',
              cascadeDelete: true,
              sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
            },
          ],
          product: [
            'Product?',
            {
              embeddedList: true,
              reverse: 'repertoire',
              cascadeDelete: true,
              sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
            },
          ],
          program: [
            'Program?',
            {
              embeddedList: true,
              reverse: 'elements',
              cascadeDelete: true,
              sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
            },
          ],
        },
      },

      Route: {
        add: {
          header: [
            'FileRef?',
            { reverse: 'onRouteHeader', cascadeDelete: true },
          ],
          logo: 'Logo?',
          logoPlacement: 'LogoPlacement?',
          hideSidebar: 'boolean?',
        },
      },
    },

    resolvers: {
      Article: {
        articles: {
          return: 'many',
          sort: [{ name: 'PUBLISHED_AT_DESC', order: ['publishedAt:desc'] }],
        },
      },

      Category: {
        categories: {
          return: 'many',
          sort: [{ name: 'ASC', order: ['sortIndex:auto'] }],
          where: {
            nodeType: ['nodeType', ['eq']],
          },
        },
      },

      Composition: {
        compositions: {
          return: 'many',
          sort: compositionsSort,
          where: {
            segment: ['segment', ['eq']],
            category: ['category', ['eq', 'in']],
            tonality: ['tonality', ['eq', 'in']],
            composer: ['composer', ['eq', 'in']],
            arranger: ['arranger', ['eq', 'in']],
            tags: ['tags.tag' as any, ['eq', 'in']],
          },
        },
      },

      Embed: {
        embeds: {
          return: 'many',
          sort: [
            { name: 'SORTED', order: ['sortIndex:auto'] },
            { name: 'CREATED_DESC', order: ['createdAt:desc'] },
          ],
        },
      },

      Event: {
        events: {
          return: 'many',
          test: '',
          where: {
            project: ['projects.project', ['eq']],
            starts: ['starts', ['gt', 'gte', 'lt', 'lte', 'eq']],
            ends: ['ends', ['gt', 'gte', 'lt', 'lte']],
            hideFromNewsletters: ['hideFromNewsletters', ['notEq']],
          },
          sort: [
            { name: 'DATE_ASC', order: ['starts:asc'] },
            { name: 'DATE_DESC', order: ['starts:desc'] },
          ],
        },
      },

      Product: {
        products: {
          return: 'many',
          where: {
            segment: ['segment', ['eq', 'in']],
          },
          sort: [{ name: 'CREATED_DESC', order: ['createdAt:desc'] }],
        },
      },

      Program: {
        programs: {
          return: 'many',
          where: {
            segment: ['segment', ['eq', 'in']],
          },
          sort: [{ name: 'CREATED_DESC', order: ['createdAt:desc'] }],
        },
      },

      Review: {
        reviews: {
          return: 'many',
          where: {
            showOnReviewsPage: ['showOnReviewsPage', ['eq']],
            showInSlideShow: ['showInSlideShow', ['eq']],
          },
          sort: [{ name: 'PUBLISHED_AT_DESC', order: ['publishedAt:desc'] }],
        },
      },
    },

    i18n: {
      'en-GB': SchemaEnGB,
    },
  } as const);

export const schema = createSchema(result, {
  cultures: ['en-GB', 'de', 'fr', 'it', 'es', 'nl', 'ja', 'zh-CN'],
  defaultCulture: 'en-GB',
});

export type SimonSchema = typeof schema;
