import { createI18nCulture } from '@donkeyjs/core';

export const SchemaEnGB = {
  nodes: {
    Embed: {
      __typename: 'Media',
    },

    FeaturedEmbed: {
      __typename: 'Media',
    },
  },
} as const;

export const simonEnGB = createI18nCulture({
  values: {
    BuyTickets: 'Buy tickets',
    Events: {
      Filter: 'Filter',
      Past: 'Past Events',
      Upcoming: 'Upcoming Events',
    },
    FindOutMore: 'Find out more >',

    GoBack: '< Go back>',
    Products: {
      Buy: 'Buy on external website',
      Download: 'Download',
      Info: 'Info',
      Order: 'Order >',
      Repertoire: 'Repertoire',
      Reviews: 'Reviews',
      Stream: 'Stream',
    },
    Repertoire: {
      And: 'and',
      AppearsOn: 'Appears on',
      At: 'at',
      CountMore: (count: number) => `${count} more`,
      ListenLive: 'Listen live on',
      Played: 'Played on',
    },
    RepertoireFilter: {
      Ensembles: 'Ensembles',
      Strings: 'String instruments',
      Wind: 'Wind instruments',
    },
    Search: 'search',
    SearchResults: {
      NoResults: 'Nothing found.',
    },
    Website: 'website',
  },
});
