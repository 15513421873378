import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import {
  getNodeRef,
  meta,
  store,
  type DataNode,
  type FieldSchema,
  type Node,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps, NodeSelectProps } from '../..';
import { admin } from '../../../admin';
import { getUserContext } from '../../../authentication';
import { List } from '../../../components/List';
import { useSortableList } from '../../../dragdrop';
import { session } from '../../../session';
import { getViewContext } from '../../../views';
import { View } from '../../../views/View';
import { Label } from '../../components/Label';
import { NodeSelect } from '../../components/NodeSelect';
import { NodeSelectCheckboxList } from '../../components/NodeSelectCheckboxList';
import { getView } from '../node/getView';
import styles from './HtmlNodeList.module.css';

export function HtmlNodeList(props: FieldRenderProps<'nodeList'>) {
  const user = getUserContext();
  const viewContext = getViewContext();
  const sortable = props.field.value && useSortableList(props.field.value);

  const state = store({
    get join() {
      const fieldName =
        !props.fieldName &&
        props.field.schema?.reverse &&
        ((
          session.app.schemaMeta?.[
            props.field.schema.type as keyof typeof session.app.schemaMeta
          ]?.join as any
        )?.[props.field.schema.reverse] as string | undefined);

      return fieldName
        ? {
            fieldName,
            ownFieldName: props.field.schema!.reverse,
            typename: (
              session.app.schema.nodes[
                props.field.schema!.type as NodeTypename<DataSchema>
              ]?.fields as any
            )[fieldName]?.type as NodeTypename<DataSchema>,
          }
        : undefined;
    },

    get fieldName() {
      return props.fieldName ?? this.join?.fieldName;
    },

    get typename() {
      const name = this.fieldName;
      const typename = props.field.schema?.type as NodeTypename<DataSchema>;
      if (!typename)
        throw new Error(
          `Type is missing in schema for field ${props.fieldName}`,
        );
      if (!name) return typename;

      const field = (session.app.schema.nodes[typename].fields as any)[name] as
        | FieldSchema
        | undefined;
      if (!field) {
        throw new Error(`Field ${name} not found in node ${typename}`);
      }
      return field.type as NodeTypename<DataSchema>;
    },

    get mode() {
      return (
        props.mode ?? (props.field.schema?.embeddedList ? 'list' : 'select')
      );
    },

    get view() {
      return props.field.schema
        ? getView(props.field.schema.type, props.view, viewContext)
        : undefined;
    },
  });

  return () => {
    if (state.mode === 'list')
      return (
        <>
          <Label
            class="input"
            label={bind(() => props.label)}
            helper={bind(() => props.helper)}
            onmount={props.onmount}
          >
            {null}
          </Label>
          <div class={styles.nodeList}>
            <List
              controls="bottom"
              ungroupDrafts
              data={bind(() => props.field.value)}
              renderAdd={bind(() => state.view?.renderAdd)}
              render={(node) => {
                const sort = sortable?.(node as any);
                return state.view ? (
                  <View
                    node={node}
                    context={props.field.value}
                    readonly={bind(() => !!props.readonly)}
                    // preventFirstOutlineClick={bind(
                    //   () => props.preventFirstOutlineClick,
                    //  )}
                    view={state.view}
                    withoutOutline
                    draggable={sort?.draggable}
                    accept={sort?.accept as any}
                    sortable={sortable}
                  />
                ) : (
                  <div class="view">
                    {() =>
                      `No view available for ${state.typename} with key ${
                        viewContext?.preferredListView
                          ? `'${viewContext?.preferredListView}' or 'default'`
                          : "'default'"
                      }`
                    }
                  </div>
                );
              }}
            />
          </div>
        </>
      );

    if (state.mode === 'checkboxes') {
      return (
        <Label
          class="input"
          label={bind(() => props.label)}
          helper={bind(() => props.helper)}
          onmount={props.onmount}
        >
          <NodeSelectCheckboxList
            typename={state.typename}
            values={bind(() =>
              state.fieldName
                ? props.field.value?.map(
                    (node) => (node as any)[state.fieldName!],
                  ) || []
                : [],
            )}
            select={(value) => {
              if (props.field.value && state.fieldName) {
                props.field.value.push({
                  [state.fieldName]: {
                    __ref: getNodeRef(value),
                  },
                });
              }
            }}
            deselect={(value) => {
              meta(
                props.field.value?.find((node) => {
                  (node as any)[state.fieldName!] === value;
                }),
              )?.delete();
            }}
          />
        </Label>
      );
    }

    const selectProps: NodeSelectProps<
      NodeTypename<DataSchema>,
      NodeTypename<DataSchema>
    > = {
      get typename() {
        return state.typename;
      },
      allowEmpty: !!props.field.schema?.optional,
      get readonly() {
        return !!props.readonly;
      },
      get mapping():
        | NodeSelectProps<
            NodeTypename<DataSchema>,
            NodeTypename<DataSchema>
          >['mapping']
        | undefined {
        return state.fieldName
          ? {
              set(node) {
                return node ? ({ [state.fieldName!]: node } as any) : null;
              },
              get(node) {
                return (node as any)[state.fieldName!];
              },
              remove(node) {
                if (state.join) {
                  meta(node).delete();
                  return true;
                }
                return false;
              },
            }
          : undefined;
      },
      get filter() {
        if (props.field.parent.key === 'tags') {
          return (node: Node) => {
            if (node.__typename !== 'Tag') return true;
            return (
              (node as DataNode<DataSchema, 'Tag'>).typename ===
              props.field.parent.typename
            );
          };
        }
        return undefined;
      },
      get value() {
        return props.field.value;
      },
      set value(value) {
        props.field.value = value as any;
      },

      get onRequestAdd() {
        return state.typename && user.isSysAdmin // user.can('insert', props.typename)
          ? async () =>
              admin.showCreateOrEditNodeDialog<
                DataSchema,
                NodeTypename<DataSchema>
              >?.({
                typename: state.typename as NodeTypename<DataSchema>,
              })
          : undefined;
      },
    };

    return (
      <Label
        class="input"
        label={bind(() => props.label)}
        helper={bind(() => props.helper)}
        onmount={props.onmount}
      >
        {jsxx(NodeSelect, selectProps)}
      </Label>
    );
  };
}
