import { createTheme } from '@donkeyjs/client';
import styles from './theme.module.css';
import cart from './theme.cart.module.css';

export const theme = () =>
  createTheme({
    colors: {
      background: '#eeeeee',
      color: '#000',
      error: '#b00020',
      accent: '#921116',
      secondary: '#8b700f',
      'text-on-error': '#ffffff',
      'text-on-accent': '#ffffff',
      'page-background': '#d8d8d8',
    },

    font: '11pt/1.5em "Source Sans Pro", sans-serif',

    styles,

    components: {
      shopping_cart: cart,
    },

    webFonts: {
      google: {
        families: [
          'Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600',
          'PT+Serif:ital@1',
        ],
      },
    },
  });
