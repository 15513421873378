import { updateViews, type Views } from '@donkeyjs/client';
import { ViewArticle } from './ViewArticle';
import { ViewComposition } from './ViewComposition';
import { ViewCompositionDetailed } from './ViewCompositionDetailed';
import { ViewEmbed } from './ViewEmbed';
import { ViewEvent } from './ViewEvent';
import { ViewProduct } from './ViewProduct';
import { ViewProgram } from './ViewProgram';
import { ViewProgramElement } from './ViewProgramElement';
import { ViewReview } from './ViewReview';
import { ViewReviewSlideShow } from './ViewReviewSlideShow';

export const views: Views = {
  Article: { default: ViewArticle },
  Composer: {
    dataPanel: {
      name: () => 'Data Panel',
      component: 'auto',
    },
  },
  Composition: {
    default: ViewComposition,
    detailed: ViewCompositionDetailed,
  },
  Embed: { default: ViewEmbed },
  Event: { default: ViewEvent },
  Product: { default: ViewProduct },
  Program: { default: ViewProgram },
  ProgramElement: { default: ViewProgramElement },
  Review: {
    default: ViewReview,
    slideshow: ViewReviewSlideShow,
  },
};

if (import.meta.hot) {
  import.meta.hot.accept((hot) => {
    updateViews(views, hot?.views);
  });
}
