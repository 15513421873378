import type { RouteDefinition } from '@donkeyjs/core';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta, store } from '@donkeyjs/proxy';
import { getUserContext } from '../authentication';
import { getI18n } from '../i18n/getI18n';
import { SlideOut } from '../layout';
import { session } from '../session';
import styles from './CompactMenu.module.css';
import { CultureMenu } from './CultureMenu';
import { getMenu, type GetMenuOptions, type MenuRoute } from './getMenu';

interface CompactMenuProps
  extends Omit<
    GetMenuOptions,
    'activeRoute' | 'user' | 'app' | 'sublevels' | 'expand'
  > {
  readonly class?: string;
  readonly routes?: MenuRoute[];
  readonly activeRoute?: RouteDefinition;
  readonly includeCultures?: boolean;
  render?(route: RouteDefinition): JSX.Children;
  renderExpandIcon?(expanded: boolean): JSX.Children;
}

export function CompactMenu(props: CompactMenuProps) {
  const user = getUserContext();
  const i18n = getI18n();

  const state = store({
    get level() {
      return (
        props.routes ||
        getMenu(
          store.clone(props, {
            activeRoute: props.activeRoute || session.router.route,
            user,
            app: meta(session.app.data[0]!).getCulture(i18n.culture),
            expand: 'all',
            sublevels: 20,
          }),
        )
      );
    },
  });

  const expanded = store<Record<string, boolean>>({});

  const renderItem = (route: MenuRoute) => {
    const isExpanded = () => expanded[route.route.id] || route.isActive;

    return (
      <li>
        {() =>
          route.submenu.length > 0 ? (
            <button
              type="button"
              class={bind(() => [
                styles.button,
                {
                  active: route.isActive,
                  expanded: isExpanded(),
                },
              ])}
              onclick={() => {
                expanded[route.route.id] = !expanded[route.route.id];
              }}
            >
              <span>
                {() =>
                  props.render ? props.render(route.route) : route.route.name
                }
              </span>
              {() =>
                props.renderExpandIcon
                  ? props.renderExpandIcon(isExpanded())
                  : isExpanded()
                    ? '▼'
                    : '►'
              }
            </button>
          ) : (
            <a
              class={bind(() => [styles.link, { active: route.isActive }])}
              href={bind(() => session.router.getPath({ route: route.route }))}
            >
              {() =>
                props.render ? props.render(route.route) : route.route.name
              }
            </a>
          )
        }
        {() =>
          route.submenu.length > 0 && (
            <SlideOut open={bind(() => isExpanded())}>
              <ul>{route.submenu.map(renderItem)}</ul>
            </SlideOut>
          )
        }
      </li>
    );
  };

  return () =>
    state.level.length > 0 && (
      <>
        <ul class={bind(() => props.class)}>
          {() => state.level.map(renderItem)}
        </ul>
        {() =>
          !props.routes && props.includeCultures && <CultureMenu showText />
        }
      </>
    );
}
