import { NodeSelect, getTheme, type ListProps } from '@donkeyjs/client';
import { PhPlus } from '@donkeyjs/phosphor-icons';
import type { DataList, DataNode } from '@donkeyjs/proxy';
import styles from './AddProgramElement.module.css';

export function AddProgramElement(
  props: ListProps<DataSchema, 'ProgramElement'>,
) {
  const theme = getTheme();

  const addInterval = () => {
    (props.data as DataList<DataSchema, 'ProgramElement'>)?.push({
      interval: true,
    });
  };

  const addComposition = (value: DataNode<DataSchema, 'Composition'>) => {
    (props.data as DataList<DataSchema, 'ProgramElement'>)?.push({
      composition: value,
    });
  };

  return (
    <div class={styles.buttons}>
      <label class={theme.class.button}>
        <PhPlus weight="regular" />
        <NodeSelect<'Composition'>
          typename="Composition"
          onchange={(value) => {
            if (value) {
              if (value) {
                addComposition(Array.isArray(value) ? value[0] : value);
              }
              return false;
            }
          }}
        />
      </label>
      <button type="button" class={theme.class.button} onclick={addInterval}>
        <PhPlus weight="regular" />
        <span>Add interval</span>
      </button>
    </div>
  );
}
