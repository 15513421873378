import { DEFAULT_BLOCKS_REQUEST } from '@donkeyjs/core';
import { bind, jsxx, setState } from '@donkeyjs/jsx-runtime';
import {
  isNode,
  meta,
  type Culture,
  type DataList,
  type DataNode,
  type Signal,
} from '@donkeyjs/proxy';
import type { FieldProps } from '../..';
import { Each } from '../../../helpers';
import { getI18n } from '../../../i18n/getI18n';
import { LoadingText, lazyComponent } from '../../../loaders';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Block } from './Block';
import { groupBlocks } from './helpers/groupBlocks';

export type BlocksProps = FieldProps<'BlockList'> & {
  readonly value: DataList<DataSchema, 'Block'>;
  readonly parent?: DataNode<DataSchema, 'Block'>;
  readonly parentNode?: DataNode<DataSchema>;
  readonly active?: boolean;
  readonly contenteditable?: boolean;
  readonly class?: JSX.ClassNames;
  readonly style?: JSX.CSSProperties | string;
  readonly nestedTextBlocks?: boolean;
};

const BlocksEditor = lazyComponent(
  'donkey.blocks-editor',
  () => import('./BlocksEditor').then((m) => m.BlocksEditor),
  'none',
);

export function LayoutBlocks(props: BlocksProps) {
  const theme = getTheme();
  const i18n = getI18n();

  if (
    meta(props.value).isLoading &&
    !meta(props.value).match?.where?.onRouteBlocks
  )
    meta(props.value)?.request({
      ...DEFAULT_BLOCKS_REQUEST,
      text: true,
    });

  const cache = new WeakMap<
    DataNode<DataSchema, 'Block'>,
    Signal<DataNode<DataSchema, 'Block'>[]>
  >();

  const state = setState({
    get culture() {
      return session.router.query.culture?.[0] as Culture | undefined;
    },
    get filtered(): DataNode<DataSchema, 'Block'>[] {
      return props.value.filter(
        (block) => block.culture === (state.culture || i18n.culture),
      );
    },
    get items() {
      return groupBlocks(cache, this.filtered, props.segment, props.parent)
        .items;
    },
  });

  return () => {
    if (meta(props.value).isLoading) return <LoadingText />;

    if (!props.readonly) return jsxx(BlocksEditor, props);

    if (!state.items.length) return null;

    return (
      <div
        class={bind(() => ['blocks', theme.class.blocks, props.class])}
        style={bind(() => props.style)}
      >
        <Each
          of={bind(() => state.items)}
          render={(groupOrBlock) =>
            !isNode(groupOrBlock) ? (
              <div
                class={bind(() => [
                  'block',
                  props.nestedTextBlocks ? undefined : 'text-blocks',
                  props.nestedTextBlocks ? undefined : theme.class.textBlocks,
                ])}
              >
                <Each
                  of={bind(() => groupOrBlock.value)}
                  render={(b) => (
                    <Block
                      value={b}
                      blocks={props.value}
                      readonly={props.readonly}
                    />
                  )}
                />
              </div>
            ) : (
              <Block
                value={groupOrBlock}
                blocks={props.value}
                readonly={props.readonly}
              />
            )
          }
        />
      </div>
    );
  };
}
